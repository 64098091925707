import * as Sentry from '@sentry/browser'

export default function sentry () {
  Sentry.init({
    dsn: 'https://b30eef9dc25ab411181fdd995fc9f4a3@o4507652357685248.ingest.de.sentry.io/4507726587166800',
    release: process.env.npm_package_version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error']
      })
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/snowmap\.fr/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
