import $ from 'jquery'
import Track from '@/map/Track'
import DOMelement from './DOMelement'

export default class Diagram extends DOMelement {
  static instances = {}

  constructor (parent) {
    let type = null
    if (parent instanceof Track) {
      type = 'track_graph'
    } else if ((parent.family === 'webcams')) {
      type = 'view_angle'
    } else {
      console.error('Unknown parent type.', parent)
      throw Error()
    }

    super(type)

    this.parent = parent
    this.name = `${type}__${this.parent.name}`

    this.promises = this.promises.concat(this.parent.promises)

    Promise.all(this.promises).then(() => {
      this[type]()
    })
  }

  parse = () => {
    const xml = new XMLSerializer().serializeToString(this.document[0])
    const svg64 = btoa(
      xml.replace(/[\u00A0-\u2666]/g, function (c) {
        return '&' + c.charCodeAt(0) + ';'
      })
    )
    this.image = $(
            `<img data-id="${this.name}" src="data:image/svg+xml;base64,${svg64}">`
    )[0]
    this.data = `data:image/svg+xml;base64,${svg64}`
  }

  view_angle = () => {
    // let orientation = this.parent.orientation - 90 + this.parent.fov / 2
    const orientation = this.parent.orientation + this.parent.fov / 2 - 90

    // Create coordinates for mask
    const radians = this.parent.fov * (Math.PI / 180)
    const cos = (Math.cos(radians) * 100) / 100
    const sin = (Math.sin(radians) * 100) / 100
    const x = ((cos + 1) / 2) * 100
    const y = ((-1 * sin + 1) / 2) * 100

    let coords = [
      [x, y],
      [50, 50],
      [100, 50],
      [100, 100],
      [0, 100],
      [0, 0],
      [100, 0]
    ]

    // Remove surrounding points according to angle
    if (this.parent.fov >= 270) {
      coords.pop()
    }
    if (this.parent.fov >= 180) {
      coords.pop()
    }
    if (this.parent.fov >= 90) {
      coords.pop()
    }

    for (const i in coords) {
      coords[i] = coords[i].join(',')
    }
    coords = coords.join(' ')

    $(this.document)
      .find('polygon')
      .attr({ points: coords })
      .attr({ transform: `rotate(${orientation} 50 50)` })

    this.parse()
  }

  track_graph = () => {
    const trackDistance = this.parent.distance
    const trackElevationDelta =
            this.parent.max_elevation - this.parent.min_elevation
    const svgWidth = 100
    const svgHeight = 60
    const widthRatio = svgWidth / trackDistance
    const heightRatio = svgHeight / trackElevationDelta

    // Path points
    let values = []
    this.parent.geojson.geometry.coordinates.forEach((point) => {
      if (point[2] === -99999) {
        point[2] = this.parent.min_elevation
      }

      values.push([
        point[3] * widthRatio, trackElevationDelta * heightRatio - (point[2] - this.parent.min_elevation) * heightRatio
      ])
    })

    const path = values.map((point) => {
      return `${point[0]} ${point[1]}`
    })

    $(this.document)
      .find('path.function')
      .attr({ d: `M ${path.join(', ')}` })
    $(this.document)
      .find('path.area')
      .attr({ d: `M ${path.join(', ')}, 100 60, 0 60` })

    // X axis texts
    const xTicks = []
    let unit = 'km'
    let multip = 1
    const nbTicks = 5
    if (trackDistance < 1) {
      unit = 'm'
      multip = 1000
    }
    for (let x = 0; x <= nbTicks; x++) {
      xTicks.push(
                `${Math.round(
                    ((trackDistance * 1) / nbTicks) * (multip * x)
                )} ${unit}`
      )
    }

    $(this.document).find('text.start').html(xTicks[0])
    $(this.document).find('text.quarter').html(xTicks[1])
    $(this.document).find('text.middle').html(xTicks[2])
    $(this.document).find('text.threeQuarters').html(xTicks[3])
    $(this.document).find('text.end').html(xTicks[4])

    // Y axis texts
    $(this.document)
      .find('text.top')
      .html(`${this.parent.max_elevation} m`)
    $(this.document)
      .find('text.bottom')
      .html(`${this.parent.min_elevation} m`)

    let dividerValue = 1000
    values = []

    let n = 0
    while (values.length < 3) {
      values = []
      const min =
                Math.ceil(this.parent.min_elevation / dividerValue) *
                dividerValue
      const max =
                Math.floor(this.parent.max_elevation / dividerValue) *
                dividerValue
      let current = min

      while (current <= max) {
        values.push(parseInt(current))
        current += dividerValue
      }
      dividerValue = dividerValue / 2

      n += 1
      if (n > 100) {
        break
      }
    }

    values.forEach((value) => {
      const height =
                svgHeight - (value - this.parent.min_elevation) * heightRatio

      if (height > 3 && height < 56) {
        // Horizontal line
        $(this.document)
          .find('.lines > line')
          .last()
          .clone()
          .attr({
            x1: 0,
            y1: height,
            x2: 100,
            y2: height
          })
          .appendTo($(this.document).find('.lines'))

        // Value text
        $(this.document)
          .find('.texts > text')
          .last()
          .clone()
          .attr({
            'text-anchor': 'end',
            x: -2,
            y: height
          })
          .html(`${value} m`)
          .appendTo($(this.document).find('.texts'))

        // Tick
        $(this.document)
          .find('.ticks > line')
          .last()
          .clone()
          .attr({
            x1: -1,
            y1: height,
            x2: 1,
            y2: height
          })
          .appendTo($(this.document).find('.ticks'))
      }
    })

    this.parse()
  }
}
