import * as L from 'leaflet'
import 'leaflet.markercluster'
import Filter from '@/ui/Filter'
import { APP, HOST } from '..'
import Article from '@/ui/Article'
import POI from './POI'

export default class POIlayer {
  constructor ({
    name,
    title,
    icon,
    filters,
    attributes,
    tiles,
    list,
    family
  } = {}) {
    this.name = name
    this.title = title
    this.icon = icon
    this.attributes = Object.fromEntries(
      Object.entries(attributes).filter((x) =>
        x[1].applies_to.includes(name)
      )
    )
    this.filters = POIlayer.getFilters(this)
    this.layer = POIlayer.createLayer({
      instance: this,
      tiles
    })
    this.list = POIlayer.createPOIs({
      instance: this,
      list,
      family
    })
    this.filterArticle = new Article({
      ...arguments[0],
      family: 'filters',
      filters: this.filters,
      promises: []
    })
    this.addedToMapTrigger()
  }

  static getFilters (instance) {
    // Get filters which apply to this
    const filters = Object.fromEntries(
      Object.entries(APP.data.filters).filter((x) =>
        x[1].applies_to.some((i) =>
          Object.keys(instance.attributes).includes(i)
        )
      )
    )

    // Create new filters from list
    for (const [name, filter] of Object.entries(filters)) {
      filters[name] = new Filter({
        data: filter,
        parent: instance
      })
    }

    return filters
  }

  static createLayer ({ instance, tiles } = {}) {
    const layer = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      removeOutsideVisibleBounds: true,
      animate: true,
      parent: instance,
      maxClusterRadius: (zoom) => {
        if (zoom > 12) {
          return 20
        } else {
          return 60
        }
      },
      spiderfyDistanceMultiplier: 5,
      spiderfyShapePositions: function (count, centerPt) {
        const circumference = 75
        const legLength = (circumference / Math.PI) * 2
        const angleStep = (Math.PI * 2) / count
        const res = []
        let i
        let angle

        res.length = count

        for (i = 0; i < count; i++) {
          angle = i * angleStep
          res[i] = new L.Point(
            centerPt.x + legLength * Math.cos(angle),
            centerPt.y + legLength * Math.sin(angle)
          )._round()
        }

        return res
      }
    })
      .on('add', (event) => {
        event.target.options.parent.addedToMapTrigger()
      })
      .on('remove', () => {
        instance.deselectAll()
      })
      .on('clusterclick', (event) => {
        instance.deselectAll()
        if (event.layer._childCount <= 10 && APP.map.getZoom() > 10) {
          event.layer.spiderfy()
        } else {
          event.layer.zoomToBounds()
        }
      })

    // Optional tileLayer
    if (tiles != null) {
      layer._nonPointGroup.addLayer(
        new L.TileLayer(tiles.url, tiles.options)
      )
    }

    return layer
  }

  static createPOIs ({ instance, list, family } = {}) {
    const POIlist = {}

    for (const [key, item] of Object.entries(list)) {
      // Create POI
      POIlist[key] = new POI({
        ...item,
        name: item.name,
        api: `${HOST}/api/${family}/${key}`,
        latitude: item.latitude,
        longitude: item.longitude,
        parent: instance,
        family
      })
    }

    return POIlist
  }

  addedToMapTrigger = () => {
    const actions = this.getFilteredPoints()
    actions.add.forEach((point) => {
      this.layer.addLayer(point.marker)
    })
    actions.remove.forEach((point) => {
      this.layer.removeLayer(point.marker)
    })
  }

  getFilteredPoints = () => {
    const actions = {
      add: [],
      remove: []
    }
    const points = Object.values(this.list)
    const filters = Object.values(this.filters)
    points.forEach((point) => {
      let score = 0
      filters.forEach((filter) => {
        score += filter.filterPOI(point)
      })
      if (score === filters.length) {
        actions.add.push(point)
      } else {
        actions.remove.push(point)
      }
    })
    return actions
  }

  deselectAll = () => {
    Object.values(this.list).forEach((poi) => {
      if (poi.selected) {
        poi.deselect()
      }
    })
  }
}
