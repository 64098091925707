// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/images/download.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/images/logo-camptocamp.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/images/logo-skitour.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../../assets/images/cc.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<article class=\"sortie\"> <h1>Sortie</h1> <input type=\"checkbox\" class=\"bookmark\" name=\"bookmark\" id=\"bookmark\"> <label for=\"bookmark\"></label> <section> <h2 class=\"title\"></h2> <span class=\"date\"></span> <h3 class=\"summary\">Résumé</h3> <div class=\"paragraph\" data-name=\"summary\"></div> <ul class=\"photos\"> <li class=\"blank\" data-url=\"\" style=\"background-image:url(../../images/empty)\"></li> <li class=\"moreImages\"><span></span></li> </ul> <ul class=\"actions\"> <li class=\"gpx\" data-action=\"download_geojson\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"Télécharger\"> <span>Télécharger la trace GPS</span> </li> </ul> <h3 class=\"description\">Description</h3> <div class=\"paragraph\" data-name=\"description\"></div> <h3 class=\"conditions\">Conditions</h3> <div class=\"paragraph\" data-name=\"conditions\"></div> <h3 class=\"story\">Récit</h3> <div class=\"paragraph\" data-name=\"story\"></div> <h3 class=\"timing\">Horaire</h3> <div class=\"paragraph\" data-name=\"timing\"></div> <h3 class=\"route_description\">Itinéraire</h3> <div class=\"paragraph\" data-name=\"route_description\"></div> <p class=\"links\"> <a href=\"https://www.camptocamp.org/\" class=\"external\" target=\"_blank\" data-site=\"c2c\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> Camptocamp.org </a> <a href=\"https://skitour.fr/\" class=\"external\" target=\"_blank\" data-site=\"skitour\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> Skitour.fr </a> <a href=\"http://creativecommons.org/licenses/by-nc-nd/3.0/deed.fr\" class=\"external\" target=\"_blank\" data-license=\"cc-by-nc-nd\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> BY-NC-ND </a> </p> </section> </article>";
// Exports
export default code;