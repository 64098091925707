import $ from 'jquery'
import { APP } from '..'
import DOMelement from '@/ui/DOMelement'
import L from 'leaflet'

export default class Location {
  constructor (map) {
    this.position = {
      lat: null,
      lng: null,
      elevation: null,
      orientation: null,
      precision: null
    }

    this.pointer = new L.Marker([0, 0], {
      icon: new L.DivIcon()
    })
    this.precisionCircle = new L.Circle([0, 0], {
      radius: 0,
      className: 'precision hidden'
    })
    this.azimuthLine = new L.Polyline([[0, 0]], {
      noClip: true,
      className: 'azimuth-line'
    })

    this.layer = new L.FeatureGroup([
      this.pointer,
      this.precisionCircle,
      this.azimuthLine
    ])

    new DOMelement('compass')
      .getCopy()
      .then((response) => {
        this.pointer.setIcon(
          new L.DivIcon({
            className: 'map-pointer hidden',
            html: response
          })
        )
      })

    map.on('move', () => {
      if (APP.state.locationEnabled) {
        this.updateAzimuthLine()
      }
    })

    $('body').attr({ 'data-location': 'false' })
  }

  update = () => {
    if (APP.state.locationEnabled) {
      this.setLocationListener(true)
      this.setCompassListener(true)
      this.layer.addTo(APP.map)
      $('body').attr({ 'data-location': 'true' })
    } else {
      this.layer.remove()
      this.setLocationListener(false)
      this.setCompassListener(false)
      $('body').attr({ 'data-location': 'false' })
    }
  }

  updateAzimuthLine = () => {
    if (
      ![this.position.lat, this.position.lng].some((x) => {
        return x == null
      })
    ) {
      this.azimuthLine.setLatLngs([
        APP.map.getCenter(),
        [this.position.lat, this.position.lng]
      ])
      this.updateMapInfo()
    }
  }

  updateMapIcons = () => {
    // Position and precision
    if (
      ![
        this.position.lat,
        this.position.lng,
        this.position.precision
      ].some((x) => {
        return x == null
      })
    ) {
      this.pointer.setLatLng([this.position.lat, this.position.lng])
      this.precisionCircle.setLatLng([
        this.position.lat,
        this.position.lng
      ])
      this.precisionCircle.setRadius(this.position.precision)
      $(this.pointer._icon).removeClass('hidden')
    }

    // Orientation
    if (this.position.orientation != null) {
      $('#map div.map-pointer svg polygon').attr({
        transform: `rotate(${this.position.orientation} 50 50)`
      })
    }
  }

  updateMapInfo = () => {
    if (this.position.elevation != null) {
      APP.ui.mapInfo.updateItem(
        'altitude',
                `${parseInt(this.position.elevation)}m`
      )
    }

    // Orientation
    let orientation = this.position.orientation
    if (APP.device.type === 'desktop') {
      orientation = parseInt(this.getAzimuthLineOrientation())
      if (orientation < 0) {
        orientation += 360
      }
    }
    const index = Math.floor(((orientation + 360 / 16) / 360) * 8)
    const name = APP.attributes.orientation.data[index].replace(
      /[^A-Z]+/g,
      ''
    )
    APP.ui.mapInfo.updateItem(
      'orientation',
            `${parseInt(orientation)}&deg ${name}`
    )

    // Distance
    if (
      ![this.position.lat, this.position.lng].some((x) => {
        return x == null
      })
    ) {
      let distance = parseInt(this.getAzimuthLineLength())
      if (distance < 1000) {
        distance = `${distance}m`
      } else if (distance > 100000) {
        distance = `${(distance / 1000).toFixed(0)}km`
      } else {
        distance = `${(distance / 1000).toFixed(2)}km`
      }
      APP.ui.mapInfo.updateItem('distance', distance)
    }
  }

  updateMapInfoHTML = (name, text) => {}

  getAzimuthLineOrientation = () => {
    const a = APP.map.location.pointer.getLatLng()
    const b = APP.map.getCenter()
    return (Math.atan2(b.lng - a.lng, b.lat - a.lat) * 180) / Math.PI
  }

  getAzimuthLineLength = () => {
    const a = APP.map.location.pointer.getLatLng()
    const b = APP.map.getCenter()
    return a.distanceTo(b)
  }

  center = () => {
    if (
      JSON.stringify(
        Object.values(APP.map.location.pointer.getLatLng())
      ) !== JSON.stringify([0, 0])
    ) {
      APP.map.setView(
        APP.map.location.pointer.getLatLng(),
        APP.map.getZoom(),
        { animate: false }
      )
      APP.map.fire('moveend')
    }
  }

  setLocationListener = (bool = true) => {
    if (!bool) {
      navigator.geolocation.clearWatch(this.watchID)
      return
    }
    if (navigator.geolocation) {
      this.watchID = navigator.geolocation.watchPosition((position) => {
        // Set object values
        this.position.lat = position.coords.latitude
        this.position.lng = position.coords.longitude
        this.position.elevation = position.coords.altitude
        this.position.precision = position.coords.accuracy

        this.updateMapIcons()
        this.updateAzimuthLine()
        this.updateMapInfo()
      })
    }
  }

  setCompassListener = (bool = true) => {
    if (!bool) {
      $(window).off('deviceorientationabsolute')
      return
    }
    if (window.DeviceOrientationEvent) {
      $(window).on('deviceorientationabsolute', (event) => {
        this.position.orientation = 360 - event.originalEvent.alpha
        this.updateMapIcons()
        this.updateAzimuthLine()
        this.updateMapInfo()
      })
    }
  }
}
