import intersect from '@turf/intersect'
import { APP, HOST } from '..'
import L from 'leaflet'
import Region from './Region'
import { tileCoordsToBounds } from '@/tools'
import { toNumber } from 'lodash'

export default class SnowLayer {
  constructor ({
    title,
    name,
    icon,
    tiles,
    regions,
    fillColors,
    timeLayer,
    avalancheRiskIcons,
    minNativeZoom = 1,
    minZoom = 1,
    maxZoom = 20,
    maxNativeZoom = 12,
    zoomOffset = 0
  } = {}) {
    this.title = title
    this.name = name
    this.icon = icon
    this.regions = regions
    this.fillColors = fillColors
    this.avalancheRiskIcons = avalancheRiskIcons
    this.tiles = tiles.startsWith('http') ? tiles : HOST + tiles

    this.layer = this.createLayer({
      name: this.name,
      tiles: this.tiles,
      minZoom,
      maxZoom,
      minNativeZoom,
      maxNativeZoom,
      zoomOffset,
      instance: this
    })
  }

  createLayer ({
    name,
    tiles,
    minZoom,
    maxZoom,
    minNativeZoom,
    maxNativeZoom,
    zoomOffset,
    instance
  } = {}) {
    const layer = new L.FeatureGroup([
      new L.TileLayer(tiles, {
        name,
        parent: instance,
        minZoom,
        maxZoom,
        minNativeZoom,
        maxNativeZoom,
        tileSize: 256 / (zoomOffset + 1),
        zoomOffset,
        className: `snowLayer ${name}`
      }).on('tileloadstart', (event) => {
        event.tile.crossOrigin = 'Anonymous'

        if (this.name === 'slopes') {
          event.tile.src = this.getSlopeSrc(event)
        }
      })
    ])
      .on('add', () => {
        instance.addedToMapTrigger()
      })
      .on('remove', () => {
        if (APP.map.getSelected() instanceof Region) {
          APP.map.deselectAll()
        }
      })

    if (name === 'itineraries') {
      layer.addLayer(new L.TileLayer(
        [
          'https://data.geopf.fr/wmts',
          '?layer=TRACES.RANDO.HIVERNALE',
          '&style=normal',
          '&tilematrixset=PM',
          '&Service=WMTS',
          '&Request=GetTile',
          '&Version=1.0.0',
          '&Format=image/png',
          '&TileMatrix={z}',
          '&TileCol={x}',
          '&TileRow={y}'
        ].join('')
        , {
          name,
          parent: instance,
          minZoom,
          maxZoom,
          minNativeZoom,
          maxNativeZoom,
          tileSize: 256 / (zoomOffset + 1),
          zoomOffset,
          className: `snowLayer ${name}`
        }))
    }

    return layer
  }

  addedToMapTrigger = () => {
    if (this.regions) {
      // Show regions limits if applicable
      Object.values(APP.regions).forEach((region) => {
        this.layer.addLayer(region.layer)
      })

      // Hide avalanche risk icons if applicable
      if (!this.avalancheRiskIcons) {
        APP.map.eachLayer((layer) => {
          if (
            layer instanceof L.Marker &&
                        !(layer instanceof L.MarkerCluster) &&
                        layer.options.parent.family === 'regions'
          ) {
            APP.map.removeLayer(layer)
          }
        })
      }
    }
    APP.map.pipette()
  }

  getSlopeSrc = (event) => {
    // Create a polygon from the tile's bounds
    const bounds = tileCoordsToBounds(event.coords)
    const geojson = {
      type: 'Polygon',
      coordinates: [
        [
          [bounds._northEast.lng, bounds._northEast.lat],
          [bounds._northEast.lng, bounds._southWest.lat],
          [bounds._southWest.lng, bounds._southWest.lat],
          [bounds._southWest.lng, bounds._northEast.lat]
        ]
      ]
    }

    // Find which region the tile is in
    let country = null
    for (const region of Object.values(APP.data.regions)) {
      const tileGeoJSON = {
        type: 'Feature',
        properties: {},
        geometry: geojson
      }

      const intersection = intersect({
        type: 'FeatureCollection',
        properties: {},
        features: [tileGeoJSON, region.geojson]
      })

      if (intersection) {
        country = region.country
        break
      }
    }
    if (country !== 'france') {
      const url = event.tile.src
      const [z, x, y] = url
        .split('&')
        .splice(-3)
        .map(i => parseInt(i.replaceAll(/[^0-9]/gi, '')))
      return `https://wmts100.geo.admin.ch/1.0.0/ch.swisstopo.hangneigung-ueber_30/default/current/3857/${z}/${x}/${y}.png`
    }

    return event.tile.src
  }

  handleTileError = (event) => {
    // If SwissTopo isn't found, actually try IGN
    if (
      event.target.options.name === 'slopes' &&
            !event.tile.src.includes('https://data.geopf.fr')
    ) {
      const url = event.tile.src
      const split = url.split('.').at(-2).split('&')
      const x = toNumber(split.at(-1))
      const y = toNumber(split.at(-2))
      const z = toNumber(split.at(-3))

      event.tile.src = [
        'https://data.geopf.fr/wmts?layer=GEOGRAPHICALGRIDSYSTEMS.SLOPES.MOUNTAIN',
        '&style=normal',
        '&tilematrixset=PM',
        '&Service=WMTS',
        '&Request=GetTile',
        '&Version=1.0.0',
        '&Format=image/png',
                `&TileMatrix=${z}`,
                `&TileCol=${x}`,
                `&TileRow=${y}`
      ].join('')
    }
  }
}
