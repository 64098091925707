import $ from 'jquery'
import { APP } from '..'
import DOMelement from './DOMelement'
import svgFiles from './svgFiles'

export default class Tabs extends DOMelement {
  constructor (tabs) {
    super('tabs.html')
    this.addToDOM($('body'))
    this.tabs = tabs
    APP.ui.promises = APP.ui.promises.concat(this.promises)
  }

  appendTabs = async () => {
    for (const tab of this.tabs) {
      this.promises.push(await tab.addToDOM('div.tabs div.panner'))
    }

    // Add map to tabs if mobile mode
    if (APP.device.type === 'mobile') {
      $('div.mainTab.map').prependTo($(this.document).find('div.panner'))
    }

    Promise.all(this.promises).then(() => {
      this.buildNavItems()
    })
  }

  buildNavItems = () => {
    $(this.document)
      .find('div.panner')
      .children()
      .each((index, item) => {
        // Add nav item
        $(this.document)
          .find('nav > ul > li.blank')
          .clone()
          .appendTo($(this.document).find('nav > ul'))
          .attr({ class: $(item).attr('data-name') })
          .children('img')
          .attr({
            src: 'data:image/svg+xml;base64,' + btoa(svgFiles[$(item).attr('data-image')])
          })
          .siblings('span')
          .html($(item).attr('data-title'))
      })
    $(this.document).find('nav > ul > li.blank').remove()

    // Get number of items
    const numberOfItems = $(this.document).find('nav > ul > li').length
    $(this.document).css({ '--numberOfItems': numberOfItems })

    // Set nav click and trigger it on the first item
    $(this.document)
      .find('nav > ul > li')
      .on('click', (event) => this.clickedNavButton(event))
    $(this.document).find('nav > ul > li').first().trigger('click')
  }

  clickedNavButton = (event) => {
    const target = event.currentTarget

    // Get index of clicked element and add it as a CSS variable
    const list = Object.values($(this.document).find('nav > ul > li'))
    const index = list.indexOf(target)
    $(this.document)
      .css({ '--index': index })
      .attr({ 'data-index': index })

    // Show neighbouring elements
    $(this.document)
      .find(`div.panner > div:not(:nth-child(${index - 1}))`)
      .removeClass('hidden')
    $(this.document)
      .find(`div.panner > div:not(:nth-child(${index + 1}))`)
      .removeClass('hidden')

    // Get animation timing and hide other sections after that time
    const duration = parseFloat(
      $(this.document).find('div.panner').css('transition-duration')
    )
    const timestamp = Date.now()
    $(this.document).attr({ 'data-animation-timestamp': timestamp })
    setTimeout(() => {
      if (
        $(this.document).attr('data-animation-timestamp') ===
                timestamp.toString()
      ) {
        const index = parseInt($(this.document).attr('data-index')) + 1
        $(this.document)
          .find(`div.panner > div:not(:nth-child(${index}))`)
          .addClass('hidden')
        $(this.document)
          .find('div.panner')
          .removeAttr('data-animation-timestamp')
      }
    }, duration * 1000)

    // Set required search bar
    APP.ui.searchBar.update()
  }

  open = (manually = false) => {
    if (this.isOpen()) {
      return true
    }

    $(this.document).removeAttr('data-manually')
    if (manually) {
      $(this.document).attr({ 'data-manually': 'true' })
    }
    if (!this.isOpen()) {
      $('body').attr({ 'data-hamburger': 'open' })
      return true
    }
    return false
  }

  opennedManually = () => {
    return $(this.document).is('[data-manually]')
  }

  isOpen = () => {
    if (APP.device.type === 'desktop' && $('body').is('[data-hamburger]')) {
      return true
    }

    return false
  }

  close = () => {
    if (APP.device.type === 'mobile') {
      $('div.tabs nav ul li:first-child').trigger('click')
      APP.ui.searchBar.update()
      return true
    } else if (APP.device.type === 'desktop') {
      if ($('body').is('[data-hamburger]')) {
        $('body').removeAttr('data-hamburger')
        APP.ui.searchBar.update()
        return true
      }
    }
    return false
  }

  getCurrent = () => {
    const index = Number($(this.document).attr('data-index')) + 1
    return $(this.document)
      .find(`.mainTab:nth-child(${index})`)
      .attr('data-name')
  }
}
