import $ from 'jquery'
import DOMelement from './DOMelement'
import getCookie from '@/utils/getCookie'

export default class DonateWindow extends DOMelement {
  constructor () {
    super('donate-window.html')

    // Set first boot cookie
    let expires = null
    if (getCookie('firstBoot')) {
      expires = new Date('October 1, 2025 00:00:00').toUTCString()
      document.cookie = `firstBoot=${Date.now()}; expires=${expires};path=/;`
    }

    // Get cookie
    const firstBootCookie = getCookie('firstBoot')
    if (
      getCookie('donateWindowShown') &&
            Date.now() - firstBootCookie > 7 * 24 * 60 * 60 * 1000
    ) {
      // 7 days
      this.addToDOM('body')
      expires = new Date(
        Date.now() + 30 * 24 * 60 * 60 * 1000
      ).toUTCString() // 30 days
      document.cookie = `firstBoot=${Date.now()}; expires=${expires};path=/;`
    }
  }

  setTriggers = () => {
    $(this.document)
      .find('.install')
      .off()
      .on('click', () => {
        $(this.document).remove()
        window
          .open('https://buy.stripe.com/bIY14pcPd67dcfubIJ', '_blank')
          .focus()
      })
    $(this.document)
      .find('.later')
      .off()
      .on('click', () => {
        $(this.document).remove()
      })
    $(this.document)
      .find('.shop')
      .off()
      .on('click', () => {
        window.open('https://snowmap.fr/shop', '_blank').focus()
      })
    $(this.document)
      .find('.playstore')
      .off()
      .on('click', () => {
        window.open('https://play.google.com/store/apps/details?id=fr.snowmap', '_blank').focus()
      })
    $(this.document)
      .find('.facebook')
      .off()
      .on('click', () => {
        window
          .open('https://www.facebook.com/snowmapofficial', '_blank')
          .focus()
      })
    $(this.document)
      .find('.instagram')
      .off()
      .on('click', () => {
        window
          .open('https://instagram.com/snowmapofficial', '_blank')
          .focus()
      })
    $(this.document)
      .find('.email')
      .off()
      .on('click', () => {
        window
          .open('mailto:snowmapofficial@gmail.com', '_blank')
          .focus()
      })
  }
}
