import { APP, HOST } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'
import { formatDate } from '@/tools'

export default class ReportsList extends DOMelement {
  constructor () {
    super('tabs/reports-list.html')
  }

  countries = () => {
    if ($(this.document).attr('data-mode') !== 'countries') {
      $(this.document).find('section[data-region]').remove()
      $(this.document).find('ul.filter li:not(.blank)').remove()
      $(this.document).attr({ 'data-mode': 'countries' })
      return true
    }
    return false
  }

  regions = (country) => {
    $(this.document).attr({ 'data-mode': 'regions' })
    $(this.document).find('section.regions:not(.blank)').remove()

    const elements = []
    Object.values(APP.regions).forEach((region) => {
      if (region.country === country) {
        elements.push(this.buildSingleReport(region))
      }
    })

    // Sort elements by massif or by name
    elements.sort((a, b) => {
      // Name alphabetical order in case massif is the same
      if ($(a).attr('data-massif') === $(b).attr('data-massif')) {
        const order = [
          $(a).attr('data-region'),
          $(b).attr('data-region')
        ].sort()

        if (order.indexOf($(a).attr('data-region')) === 0) {
          return -1
        } else {
          return 1
        }
      }

      const order = [
        $(a).attr('data-massif'),
        $(b).attr('data-massif')
      ].sort()

      if (order.indexOf($(a).attr('data-massif')) === 0) {
        return -1
      } else {
        return 1
      }
    })

    // Append sorted elements
    $(this.document).find('article').append(elements)

    // Update filter
    this.buildFilterButtons()

    // Update searchbar
    APP.ui.searchBar.update()
  }

  setTriggers = () => {
    $(this.document)
      .find('section.countries li')
      .on('click', (event) => {
        this.regions($(event.currentTarget).attr('class'))
      })
  }

  buildSingleReport = (region) => {
    const element = $(this.document).find('section.blank').clone()

    $(element)
      .attr({ 'data-region': region.name })
      .removeAttr('class')
      .appendTo($(this.document).find('article'))

    if (region.massif) {
      $(element).attr({ 'data-massif': region.massif })
    }

    // Titles
    $(element).find('h2').html(region.title)
    $(element)
      .find('h3')
      .html(
                `${region.highest_point_name} ${region.highest_point_elevation}m`
      )

    // Icons
    $(element)
      .find('p.icons > span > img')
      .each(async (_, el) => {
        const name = $(el).attr('class')
        $(el).attr({
          src: `${HOST}/images/diagrams/${name}/${region.name}.svg`
        })
      })

    // Date text
    if (region.report.timestamp !== 0) {
      const timePast = parseInt(
        (Date.now() - region.report.timestamp * 1000) / 1000 / 60 / 60
      )
      const date = formatDate(
        new Date(region.report.timestamp * 1000),
        'frenchDateTime'
      )
      $(element)
        .find('h4.date')
        .append(`Données du ${date} (${timePast}h)`)
      if (timePast > 20) {
        $(element).find('h4.date').addClass('warning')
      }
    }

    $(element).on('click', (event) => {
      const region = $(event.currentTarget).attr('data-region')
      APP.regions[region].openArticle()
    })

    return element
  }

  buildFilterButtons = () => {
    // Remove old buttons
    $(this.document).find('ul.filter li:not(.blank)').remove()

    // Get massifs list
    let massifs = []
    $(this.document)
      .find('section')
      .each((index, element) => {
        const massif = $(element).attr('data-massif')
        massifs.push(massif)
      })
    massifs = [...new Set(massifs)].filter((item) => item)

    // Add filter buttons
    massifs.forEach((massif) => {
      const button = $(this.document).find('ul.filter li.blank').clone()
      $(button)
        .find('label')
        .html(APP.data.massifs[massif].title)
        .attr({ for: `filter_${massif}` })
      $(button)
        .find('input')
        .attr({
          name: `filter_${massif}`,
          id: `filter_${massif}`,
          value: massif,
          checked: 'checked'
        })
      $(button)
        .removeAttr('class')
        .appendTo($(this.document).find('ul.filter'))

      $(this.document)
        .find('ul.filter li input')
        .on('input', () => {
          this.filter()
        })
    })
  }

  filter = () => {
    const value = $(APP.ui.searchBar.document).find('input.filter').val()

    // Get checkbox states
    const checked = []
    $(this.document)
      .find('ul > li > input:checked')
      .each((_, element) => {
        checked.push($(element).val())
      })

    $(this.document)
      .find('article > section[data-region]')
      .each((_, element) => {
        $(element).css({ display: '' })

        if (
          value != null &&
                    !$(element)
                      .attr('data-region')
                      .toLowerCase()
                      .startsWith(value.toLowerCase())
        ) {
          $(element).css({ display: 'none' })
        }
        if (!checked.includes($(element).attr('data-massif'))) {
          $(element).css({ display: 'none' })
        }
      })
  }

  getMode = () => {
    return $(this.document).attr('data-mode')
  }
}
