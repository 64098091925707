// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../../assets/images/track.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../../assets/images/upload.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../../assets/images/bin.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../../../assets/images/download.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<article class=\"tracks\"> <h1><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> Mes traces GPS</h1> <section> <p> <input type=\"file\" name=\"\" id=\"inputfile_\" accept=\".kml,.gpx\"> <label for=\"inputfile_\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> <span>Importer un fichier GPX/KML</span> </label> </p> <ul> <li class=\"blank\"> <div> <p></p> <ul class=\"data\"> <li class=\"blank\"> <img/> <span></span> </li> </ul> </div> <ul class=\"actions\"> <li data-action=\"delete\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"></li> <li> <input type=\"checkbox\" class=\"show-on-map\"> <label></label> </li> <li data-action=\"download\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"></li> </ul> </li> </ul> </section> </article>";
// Exports
export default code;