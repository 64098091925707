import { hex2hsv, hex2rgb, rgb2hsv } from '@/tools'
import { APP } from '..'

export default class Color {
  constructor ({ name, content, unit, stepped } = {}) {
    this.name = name
    this.unit = unit
    this.stepped = stepped
    this.raw = this.getColorsDetails(content)
  }

  getColorsDetails = (list) => {
    for (const [key, item] of Object.entries(list)) {
      list[key] = {
        ...item,
        hsv: hex2hsv(item.color),
        rgb: hex2rgb(item.color)
      }
    }

    return list
  }

  color2value = (rgb) => {
    if (JSON.stringify(rgb) === JSON.stringify([0, 0, 0])) {
      return NaN
    }

    // Everything is done with hue value
    const n = rgb2hsv(rgb)[0]

    // Create array sorted by value
    const values = Object.values(this.raw)
    values.sort((a, b) => {
      return a.value - b.value
    })

    let closest = values[0]

    for (const index of values.keys()) {
      // Find closest
      if (
        Math.abs(values[index].hsv[0] - n) <
                Math.abs(closest.hsv[0] - n)
      ) {
        closest = values[index]
      }

      if (this.stepped) {
        continue
      }

      // Interpolate
      if (index !== 0) {
        const a = {
          value: values[index - 1].value,
          hue: values[index - 1].hsv[0]
        }
        const b = {
          value: values[index].value,
          hue: values[index].hsv[0]
        }

        if ((n <= a.hue && n >= b.hue) || (n <= b.hue && n >= a.hue)) {
          const distanceAn = Math.abs(a.hue - n)
          const distanceBn = Math.abs(b.hue - n)
          const distanceAb = Math.abs(a.hue - b.hue)
          const hdistanceAb = Math.abs(a.value - b.value)

          const factors = {
            a: (distanceAb - distanceAn) / distanceAb,
            b: (distanceAb - distanceBn) / distanceAb
          }

          let value = null
          if (a.hue < b.hue) {
            value = a.value + hdistanceAb * factors.b
          }
          if (b.hue < a.hue) {
            value = b.value - hdistanceAb * factors.a
          }

          return parseInt(Math.round(value))
        }
      }
    }

    // If value still hasn't been found, find the closest
    return parseInt(Math.round(closest.value))
  }

  value2color = (value) => {
    // Create sorted items array
    const values = Object.values(this.raw)
    values.sort((a, b) => {
      return a.value - b.value
    })

    // Find between which colors the input stands
    for (const index of values.keys()) {
      if (index !== 0) {
        const floor = values[index - 1].value
        const ceil = values[index].value
        const distance = ceil - floor
        const distanceToValue = value - floor
        const factor = distanceToValue / distance

        if (floor <= value && ceil >= value) {
          // Convert it to a value
          const color = [
            values[index - 1].rgb[0] +
                            factor *
                                (values[index].rgb[0] -
                                    values[index - 1].rgb[0]),
            values[index - 1].rgb[1] +
                            factor *
                                (values[index].rgb[1] -
                                    values[index - 1].rgb[1]),
            values[index - 1].rgb[2] +
                            factor *
                                (values[index].rgb[2] -
                                    values[index - 1].rgb[2])
          ]
          return [
            parseInt(color[0]),
            parseInt(color[1]),
            parseInt(color[2])
          ]
        }
      }
    }

    return [0, 0, 0]
  }

  color2text = (rgb) => {
    let value = this.color2value(rgb)

    if (value == null) {
      value = 0
    }
    const unit = APP.colors[APP.state.snowLayer].unit
    let text = unit[0] + String(value) + unit[1]

    const titles = Object.fromEntries(
      APP.colors.fresh_snow.raw.map((x) => {
        return [x.value, x.title]
      })
    )
    if (value in titles && titles[value]) {
      text = titles[value]
    }

    return text
  }
}
