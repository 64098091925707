export default class Attribute {
  constructor ({ data, name, title, type, applies_to, in_data_list } = {}) {
    this.data = data
    this.name = name
    this.title = title
    this.type = type
    this.applies_to = applies_to
    this.in_data_list = in_data_list
  }

  parse = (value) => {
    switch (this.type) {
      case 'cotation':
        if (value == null) {
          return null
        }
        return parseInt(value)
      case 'value':
        if (value == null) {
          return null
        }
        return parseInt(value)
      case 'bool':
        if (value == null) {
          return null
        }
        return Boolean(value)
      case 'date':
        return new Date(value)
      default:
        throw new Error(`Invalid attribute type (${value}).`)
    }
  }
}
