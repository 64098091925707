import $ from 'jquery'
import { APP } from '..'
import DOMelement from './DOMelement'
import Article from './Article'

export default class SearchBar extends DOMelement {
  constructor () {
    super('search-bar.html')
    this.addToDOM($('body'))
    this.update()
    APP.ui.promises = APP.ui.promises.concat(this.promises)
  }

  setTriggers = () => {
    // Remove form submit event
    $(this.document)
      .find('form')
      .on('submit', (event) => {
        event.preventDefault()
      })

    // Set search submit event
    $(this.document)
      .find('input[type="search"].search')
      .on('keypress', (event) => {
        const value = $(event.currentTarget).val()
        if (event.keyCode === 13 && value !== '') {
          event.preventDefault()
          this.submit(value)
        }
      })

    // Set input event
    $(this.document)
      .find('input[type="search"].filter')
      .on('input', () => {
        APP.ui.reportsList.filter()
      })
    $(this.document)
      .find('input[type="search"].search')
      .on('input', () => {
        $(this.document).attr({ 'data-mode': 'search' })
      })

    // Set back button event
    $(this.document)
      .find('button.back')
      .on('click', () => {
        this.backButtonClicked()
      })

    // Set erase button event
    $(this.document)
      .find('button.erase')
      .on('click', () => {
        this.reset(true)
      })
  }

  submit = async (query) => {
    APP.data.searchEngine.layer?.remove()
    this.loading()
    $(this.document).find('input').blur()

    // Set expected results ID onto search results div
    const timestampNow = Date.now()
    $(this.document)
      .find('ul.results')
      .attr({ 'data-resultsID': timestampNow })

    const data = await APP.data.searchEngine.search(query, timestampNow)

    // If div results ID still match data results ID, add it to the list
    const expectedTimestamp = parseInt(
      $(this.document).find('ul.results').attr('data-resultsID')
    )
    if (data.properties.timestamp === expectedTimestamp) {
      let n = 0
      const length = data.features.length

      if (length) {
        APP.data.searchEngine.makeLayer(data)
        APP.map.flyToBounds(APP.data.searchEngine.layer.getBounds())
      }

      data.features.forEach((point) => {
        $(this.document)
          .find('ul.results li.blank')
          .clone()
          .removeAttr('class')
          .appendTo('div.searchBar ul.results')
        $(this.document)
          .find('ul.results li:last-child')
          .attr({
            class: point.properties.category,
            style: `--order: ${n}; --reverse-order: ${
                            length - n - 1
                        };`,
            'data-lat': point.geometry.coordinates[1],
            'data-lng': point.geometry.coordinates[0]
          })
        $(this.document)
          .find('ul.results li:last-child > span')
          .html(point.properties.name)
        if (isNaN(point.geometry.coordinates[2])) {
          $(this.document)
            .find('ul.results li:last-child div span.altitude')
            .remove()
        } else {
          $(this.document)
            .find('ul.results li:last-child div span.altitude')
            .html(`${parseInt(point.geometry.coordinates[2])} m`)
        }
        $(this.document)
          .find('ul.results li:last-child div span.description')
          .html(point.properties.type)
        $(this.document)
          .find('ul.results li:last-child div span.departement')
          .html(point.properties.region)
        $(this.document).find('ul.results li:last-child').attr({
          'data-country': point.properties.country
        })
        n += 1
      })
      if (!length) {
        $(this.document)
          .find('ul.results li.blank')
          .clone()
          .removeAttr('class')
          .appendTo('div.searchBar ul.results')
        $(this.document).find('ul.results li:last-child').attr({
          class: 'no-results',
          style: '--order: 0; --reverse-order: 0;',
          'data-lat': 0,
          'data-lng': 0
        })
        $(this.document)
          .find('ul.results li:last-child span')
          .html('Aucun résultat')
        $(this.document)
          .find('ul.results li:last-child div span.altitude')
          .html('')
        $(this.document)
          .find('ul.results li:last-child div span.departement')
          .html('')
        $(this.document)
          .find('ul.results li:last-child div span.description')
          .html('')
      }

      // Remove results ID
      $(this.document).find('ul.results').removeAttr('data-resultsID')

      $(this.document)
        .find('ul.results > li:not(.no-results)')
        .on('click', (event) => {
          this.resultItemClicked(event.currentTarget)
        })
        .on('mouseover', (event) => {
          APP.data.searchEngine.layer.eachLayer((layer) => {
            const layerCoords = [
              layer.getLatLng().lat,
              layer.getLatLng().lng
            ]
            const elementCoords = [
              parseFloat($(event.currentTarget).attr('data-lat')),
              parseFloat($(event.currentTarget).attr('data-lng'))
            ]

            if (
              layerCoords[0] === elementCoords[0] &&
                            layerCoords[1] === elementCoords[1]
            ) {
              $(layer._icon).addClass('highlighted')
            } else {
              $(layer._icon).removeClass('highlighted')
            }
          })
        })
        .on('mouseleave', (event) => {
          APP.data.searchEngine.layer.eachLayer((layer) => {
            $(layer._icon).removeClass('highlighted')
          })
        })

      $(this.document).attr({ 'data-mode': 'searchResults' })
    }
  }

  loading = () => {
    APP.data.searchEngine.layer?.remove()
    $(this.document).attr({ 'data-mode': 'loading' })
    $(this.document).find('ul.results li:not(.blank)').remove()
  }

  filter = () => {
    APP.data.searchEngine?.layer?.remove()
    $(this.document).attr({ 'data-mode': 'filter' })
    $(this.document).find('ul.results li:not(.blank)').remove()
    $(this.document)
      .find('input[type="search"].filter')
      .val('')
      .trigger('input')
  }

  search = () => {
    APP.data.searchEngine?.layer?.remove()
    $(this.document).attr({ 'data-mode': 'search' })
    $(this.document).find('ul.results li:not(.blank)').remove()
    $(this.document)
      .find('input[type="search"].search')
      .val('')
      .trigger('input')
  }

  back = () => {
    APP.data.searchEngine.layer?.remove()
    $(this.document).attr({ 'data-mode': 'back' })
    $(this.document).find('ul.results li:not(.blank)').remove()
    $(this.document).find('input[type="search"]').val('')
  }

  reset = (focus) => {
    APP.data.searchEngine.layer?.remove()
    $(this.document).attr({ 'data-mode': 'search' })
    $(this.document).find('ul.results li:not(.blank)').remove()
    $(this.document).find('input').val('').trigger('input')
    if (focus) {
      $(this.document).find('input[type=search]:visible').focus()
    }
  }

  resultItemClicked = (target) => {
    this.reset()
    const lat = $(target).attr('data-lat')
    const lng = $(target).attr('data-lng')
    APP.map.setView([lat, lng], 13)
    APP.data.searchEngine.layer?.remove()
  }

  backButtonClicked = () => {
    // Close carousel
    if (APP.ui.carousel.close()) {
      this.update()
      return 'carousel'
    }

    // Close article
    if (Article.close()) {
      this.update()
      if (
        APP.device.type === 'desktop' &&
                !APP.ui.tabs.opennedManually()
      ) {
        APP.ui.tabs.close()
      }
      return 'article'
    }

    // Close countries reports list
    if (APP.ui.reportsList.countries()) {
      this.update()
      return 'reportsList'
    }

    // Close bookmark subages
    if (APP.ui.bookmarks.setPage('root')) {
      this.update()
      return 'bookmarks'
    }

    // Close hamburger menu
    if (APP.ui.tabs.close()) {
      return 'tabs'
    }

    // Deselect all
    if (APP.device.type === 'mobile' && APP.map.getSelected() != null) {
      this.update()
      APP.map.deselectAll()
      return 'deselectAll'
    }

    // Open hamburger
    if (APP.ui.tabs.open(true)) {
      this.update()
      return 'tabs'
    }

    this.update()
    return null
  }

  update = () => {
    // Is the carousel open?
    if (APP.ui?.carousel.isOpen()) {
      this.back()
      return 'carousel'
    }

    // Is an article open?
    if (Article.getOpenArticle()) {
      this.back()
      return 'article'
    }

    // Are we on the map?
    if (
      APP.device.type === 'mobile' &&
            APP?.ui?.tabs?.getCurrent() === 'map'
    ) {
      this.search()
      return 'map'
    }

    // Are we on the reports tab?
    if (APP?.ui?.tabs?.getCurrent() === 'reportsList') {
      if (
        APP.device.type !== 'desktop' ||
                $('body').is('[data-hamburger]')
      ) {
        APP.ui.reportsList.getMode()
        // Which mode?
        switch (APP.ui.reportsList.getMode()) {
          case 'countries':
            this.back()
            break
          case 'regions':
            this.filter()
            break
        }
        return 'reportsList'
      }
    }

    // mobile or hamburger open
    if (APP.device.type === 'mobile' || APP?.ui?.tabs?.isOpen()) {
      if (APP?.ui?.tabs?.getCurrent() === 'settings') {
        this.back()
        return 'settings'
      }

      if (APP?.ui?.tabs?.getCurrent() === 'bookmarks') {
        this.back()
        return 'bookmarks'
      }
    }

    this.search()
    return false
  }
}
