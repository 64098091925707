import { capitalizeFirstLetter, pointInRegion, retry } from '@/tools'
import { APP, HOST } from '..'
import L from 'leaflet'
import empty from '../../assets/images/empty.svg'

export default class SearchEngine {
  constructor () {
    this.resultsNumberLimit = 20
  }

  search = async (query, timestamp) => {
    const response = await fetch(`${HOST}/api/v2/location?query=${query}`)

    const features = await response.json()

    return {
      type: 'FeatureCollection',
      features: features.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          category: feature.properties.type,
          name: feature.properties.title
        }
      })),
      properties: {
        timestamp
      }
    }
  }

  makeLayer = (response) => {
    // Create layer
    this.layer = new L.GeoJSON(response)

    const icon = new L.Icon({
      className: 'icon searchResult',
      iconUrl: 'data:image/svg+xml;base64,' + btoa(empty)
    })

    this.layer.eachLayer((l) => {
      l.setIcon(icon)
      l.on('click', (event) => {
        APP.map.setView(event.latlng, 13)
        APP.data.searchEngine.layer?.remove()
        APP.ui.searchBar.reset()
      })
    })
    APP.map.addLayer(this.layer)
  }

  minambiente_search = async (query) => {
    const url = [
      'https://www.pcn.minambiente.it/viewer/PHP/autocomplete.php?',
            `term=${encodeURI(query)}`
    ].join('')

    const collection = {
      type: 'FeatureCollection',
      features: [],
      properties: {}
    }

    return retry({
      url,
      maxRetries: 5
    })
      .then((response) => {
        if (response[0].shape === 'Nessun risultato trovato') {
          return collection
        }

        for (const item of Object.values(response)) {
          item.shape = JSON.parse(item.shape)
          const region = pointInRegion([
            item.shape.coordinates[0],
            item.shape.coordinates[1]
          ])
          if (region == null) {
            continue
          }

          collection.features.push({
            type: 'Feature',
            properties: {
              name: capitalizeFirstLetter(
                item.value.split(',')[0].toLowerCase()
              ),
              type: null,
              category: null,
              region: region.title,
              country: region.country
            },
            geometry: {
              type: 'Point',
              coordinates: [
                Number(
                  item.shape.coordinates[0].toFixed(4)
                ),
                Number(
                  item.shape.coordinates[1].toFixed(4)
                )
              ]
            }
          })
        }

        return collection
      })
      .catch((e) => {
        console.error(e)
        return null
      })
  }

  idee_search = async (query) => {
    const url = [
      'https://www.idee.es/communicationsPoolServlet/SearchAssistant?',
            `name_equals=${encodeURI(query)}`
    ].join('')

    const collection = {
      type: 'FeatureCollection',
      features: [],
      properties: {}
    }

    return retry({
      url,
      maxRetries: 5
    })
      .then((response) => {
        if (response.totalResultsCount === 0) {
          return collection
        }

        for (const item of Object.values(Object.fromEntries(response.results))) {
          // https://www.idee.es/communicationsPoolServlet/Dispatcher?request=OpenQuerySource&query=<ogc:Filter><ogc:FeatureId+fid="ES.IGN.NGBE.1788151"/></ogc:Filter>&sourcename=http://www.idee.es/communicationsPoolServlet/sourceAccessWFS-INSPIRE-NGBE.rdf&outputformat=application/json

          item.shape = JSON.parse(item.shape)

          /* let region = pointInRegion([
                    item.shape['coordinates'][0],
                    item.shape['coordinates'][1]
                ])
                if(region == undefined){
                    continue
                } */

          /* collection.features.push({
                    "type": "Feature",
                    "properties": {
                        'name': capitalizeFirstLetter(item.value.split(',')[0].toLowerCase()),
                        'type': null,
                        'category': null,
                        'region': region.title,
                        'country': region.country
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            item['shape']['coordinates'][0],
                            item['shape']['coordinates'][1]
                        ]
                    }
                }) */
        }

        return collection
      })
      .catch((e) => {
        console.error(e)
        return null
      })
  }

  getElevations = async (features) => {
    const promises = []
    for (const feature of features.features) {
      const promise = APP.data.elevationEngine
        .point(feature.geometry.coordinates)
        .then((response) => {
          feature.geometry = response
        })
      promises.push(promise)
    }

    await Promise.all(promises)

    return features
  }
}
