// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../assets/images/snowmap.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../assets/images/logo-google-play.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../assets/images/shop.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../assets/images/logo-facebook.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("../../assets/images/logo-instagram.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("../../assets/images/logo-gmail.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("../../assets/images/clock.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("../../assets/images/pay.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var code = "<div class=\"donate-window\"> <div class=\"disclaimer\"> <div class=\"scroll\"> <h1> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> Faire un don </h1> <p>Snowmap est <strong>entièrement gratuit</strong>, néanmoins nous avons besoin de votre soutien pour continuer l'aventure. Vous pouvez <strong>télécharger l'application sur le Play Store</strong>, <strong>faire un don</strong>, ou bien <strong>une commande sur notre shop</strong>. </p> <p> </p><ul class=\"actions\"> <li class=\"playstore\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"Play Store\"/> Application Snowmap sur le Play Store </li> <li class=\"shop\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"Shop\"/> Le shop </li> <li class=\"facebook\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"Facebook\"/> Facebook </li> <li class=\"instagram\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"Instagram\"/> Instagram </li> <li class=\"email\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"GMail\"/> EMail </li> </ul> <p></p> <p> Retrouvez ces liens dans les paramètres de l'application. </p> </div> <div class=\"actions\"> <button class=\"later\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\"> Plus tard </button> <button class=\"install\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\"> Faire un don </button> </div> </div> </div>";
// Exports
export default code;