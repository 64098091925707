import { HOST } from '@/index'

export default async function elevationDiagram (linestring) {
  const response = await fetch(`${HOST}/api/v2/elevation/diagram`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(linestring)
  })

  return await response.blob()
}
