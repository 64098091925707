// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/images/tracks.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/images/report.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/images/sorties.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../../assets/images/topos.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("../../../assets/images/refuges.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("../../../assets/images/stations.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("../../../assets/images/webcams.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("../../../assets/images/upload.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var code = "<div class=\"mainTab bookmarks\" data-searchbar=\"back\" data-image=\"rating\" data-title=\"Favoris\" data-name=\"bookmarks\" data-scroller=\"true\" data-mode=\"root\"> <article class=\"root\"> <h1>Favoris</h1> <section> <h2>Catégories</h2> <p> Sauvegardez vos traces, points d'intérêt et bulletins favoris pour y accéder rapidement. Vos favoris ne sont pas stockés sur nos serveurs et seront disponibles uniquement sur cet appareil. <br/> Pour ajouter un point d'intérêt, cliquez sur l'étoile après avoir ouvert la fiche le concernant. </p> <ul class=\"categories\"> <li class=\"tracks\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <p> <span>Traces GPS</span> <span class=\"info\">12 élements</span> </p> </li> <li class=\"regions\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> <p> <span>Bulletins</span> <span class=\"info\">Aucun élement</span> </p> </li> <li class=\"sorties\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> <p> <span>Sorties</span> <span class=\"info\">12 élements</span> </p> </li> <li class=\"topos\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> <p> <span>Topos</span> <span class=\"info\">12 élements</span> </p> </li> <li class=\"refuges\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\"> <p> <span>Refuges</span> <span class=\"info\">12 élements</span> </p> </li> <li class=\"stations\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\"> <p> <span>Stations</span> <span class=\"info\">12 élements</span> </p> </li> <li class=\"webcams\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\"> <p> <span>Webcams</span> <span class=\"info\">12 élements</span> </p> </li> </ul> </section> <section> <p> <input type=\"file\" name=\"\" id=\"inputfile\" accept=\".kml,.gpx\"> <label for=\"inputfile\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\"> <span>Importer un fichier GPX/KML</span> </label> </p> </section> </article> </div>";
// Exports
export default code;