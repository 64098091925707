import $ from 'jquery'
import FormControl from './FormControl'
import { formatDate } from '@/tools'

export default class DateRange extends FormControl {
  constructor (data) {
    super('date-range', data)

    Promise.all(this.promises).then(() => {
      this.setValue()
      this.setTriggers()
      this.trigger()
    })
  }

  setValue = () => {
    $(this.document)
      .find('input[type=date]')
      .each((_, element) => {
        const i =
                    ($(element).attr('data-minmax') === 'min') === false ? 1 : 0
        $(element)[0].valueAsDate = this.filter.value[i]
      })
    this.trigger()
  }

  getValue = () => {
    const value = []
    $(this.document)
      .find('input[type=date]')
      .each((_, element) => {
        if ($(element).is('[data-minmax=min]')) {
          value[0] = new Date($(element).attr('data-value'))
        }
        if ($(element).is('[data-minmax=max]')) {
          value[1] = new Date($(element).attr('data-value'))
        }
      })

    return value
  }

  setTriggers = () => {
    $(this.document)
      .find('input[type=date]')
      .off()
      .on('change', (event) => {
        const target = event.currentTarget
        const parent = $(target).parent()
        const whoChanged = $(target).data('minmax')
        let minValue = new Date(
          $(parent)
            .children('input[type=date][data-minmax=min]')
            .val()
        )
        let maxValue = new Date(
          $(parent)
            .children('input[type=date][data-minmax=max]')
            .val()
        )
        const today = new Date()

        if (minValue > today) {
          minValue = today
          $(parent).children(
            'input[type=date][data-minmax=min]'
          )[0].valueAsDate = minValue
        }
        if (maxValue > today) {
          maxValue = today
          $(parent).children(
            'input[type=date][data-minmax=max]'
          )[0].valueAsDate = maxValue
        }

        if (minValue > maxValue) {
          if (whoChanged === 'min') {
            $(target).siblings(
              'input[type=date][data-minmax=max]'
            )[0].valueAsDate = minValue
            $(target)
              .siblings('input[type=date][data-minmax=max]')
              .attr(
                'data-value',
                formatDate(minValue, 'yyyy-mm-dd')
              )
          } else {
            $(target).siblings(
              'input[type=date][data-minmax=min]'
            )[0].valueAsDate = maxValue
            $(target)
              .siblings('input[type=date][data-minmax=min]')
              .attr(
                'data-value',
                formatDate(maxValue, 'yyyy-mm-dd')
              )
          }
        }

        $(target).attr('data-value', $(target).val())
        this.updateFilterValue()
      })
    $(this.document)
      .find('button')
      .off()
      .on('click', (event) => {
        const target = event.currentTarget
        const parent = $(target).parent()
        const whoChanged = $(target)
          .prevAll('input[type=date]')
          .data('minmax')
        const date = new Date(
          $(target).prevAll('input[type=date]').val()
        )
        if ($(target).hasClass('left')) {
          date.setDate(date.getDate() - 1)
          $(target).prevAll('input[type=date]')[0].valueAsDate = date
        } else {
          date.setDate(date.getDate() + 1)
          $(target).prevAll('input[type=date]')[0].valueAsDate = date
        }
        // Do not use .trigger so that we can differentiate with setState()
        $(parent)
          .children(
            'input[type=date][data-minmax=' + whoChanged + ']'
          )[0]
          .dispatchEvent(new Event('change'))
        this.updateFilterValue()
      })
  }

  trigger = () => {
    $(this.document).find('input[type=date]').trigger('change')
  }
}
