import Hammer from 'hammerjs'
import { APP } from '..'
import DOMelement from './DOMelement'
import $ from 'jquery'
import { download, formatDate, uri } from '@/tools'

export default class Carousel extends DOMelement {
  constructor () {
    super('carousel.html')
    APP.ui.promises = APP.ui.promises.concat(this.promises)
  }

  setTriggers = () => {
    $(this.document)
      .find('button.download')
      .off()
      .on('click', () => {
        this.downloadCurrent()
      })
    $(this.document)
      .find('li.next')
      .off()
      .on('click', () => {
        this.next()
      })
    $(this.document)
      .find('li.previous')
      .off()
      .on('click', () => {
        this.previous()
      })
    $(this.document)
      .find('ul.thumbnails li')
      .off()
      .on('click', (event) => {
        const index = $(event.target).index()
        this.goToIndex(index)
      })
    $(this.document)
      .find('ul.images li img')
      .each((i, element) => {
        this.setPanZoom(element)
        $(element)
          .css({
            transform: 'matrix(1, 0, 0, 1, 0, 0)'
          })
          .attr({ 'data-start-matrix': 'matrix(1, 0, 0, 1, 0, 0)' })
      })
  }

  setPanZoom = (element) => {
    const hammertime = new Hammer(element)

    // Swipe
    hammertime.on('swipe', (e) => {
      if (
        $(e.target).css('transform') === 'matrix(1, 0, 0, 1, 0, 0)' &&
                !$(e.target).parents('ul').first().is('[data-just-swiped]')
      ) {
        // Reset zoom on all images

        switch (e.direction) {
          case 2:
            this.next()
            break
          case 4:
            this.previous()
            break
        }

        $(e.target)
          .parents('ul')
          .first()
          .attr({ 'data-just-swiped': 'true' })

        setTimeout(() => {
          $(e.target)
            .parents('ul')
            .first()
            .removeAttr('data-just-swiped')
        }, 500)
      }
    })

    // Double tap
    hammertime.on('doubletap', (e) => {
      $(e.target)
        .css({
          transform: 'matrix(1, 0, 0, 1, 0, 0)'
        })
        .attr({ 'data-start-matrix': 'matrix(1, 0, 0, 1, 0, 0)' })
    })

    hammertime.get('pan').set({ enable: true })
    hammertime.get('pinch').set({ enable: true })

    hammertime.on('panmove pinchmove', (e) => {
      // Get matrix
      let matrix = $(e.target)
        .attr('data-start-matrix')
        .replace(/[^0-9\-.,]/g, '')
        .split(',')
        .map((item) => Number(item))

      // Translation
      matrix[4] += e.deltaX
      matrix[5] += e.deltaY

      // Scale
      if ('scale' in e) {
        const scale = Math.min(10, Math.max(1, e.scale * matrix[0]))

        matrix[0] = scale
        matrix[3] = scale
      }

      // Find egdes position and clamp translation values
      const width = $(e.target)[0].getBoundingClientRect().width
      const left =
                $(e.target).offset().left - $(e.target).parent().offset().left
      const parentWidth = $(e.target).parent().width()
      const leftEdge = Math.max(0, parseInt(left))
      const rightEdge = Math.max(
        0,
        parseInt((left + width - parentWidth) * -1)
      )
      matrix[4] -= leftEdge
      matrix[4] += rightEdge

      const height = $(e.target)[0].getBoundingClientRect().height
      const top =
                $(e.target).offset().top - $(e.target).parent().offset().top
      const parentHeight = $(e.target).parent().height()
      const topEdge = Math.max(0, parseInt(top))
      const bottomEdge = Math.max(
        0,
        parseInt((top + height - parentHeight) * -1)
      )

      if (height <= parentHeight) {
        matrix[5] = 0
      } else {
        matrix[5] -= topEdge
        matrix[5] += bottomEdge
      }

      // Apply new matrix
      matrix = `matrix(${matrix.join(',')})`
      $(e.target).css({
        transform: matrix
      })
    })

    hammertime.on('panend pinchend', (e) => {
      // Set start transform matrix
      $(e.target).attr({
        'data-start-matrix': $(e.target).css('transform')
      })
    })
  }

  open = ({ poi, target } = {}) => {
    this.addToDOM('div.tabs')

    let index = 0
    if (target) {
      index = $(target).index()
    }

    $(this.document).css({ '--data-index': index })
    $(this.document).find('ul.images').empty()
    $(this.document).find('ul.thumbnails').empty()

    poi.photos.forEach((photo) => {
      const name = `${uri(poi.title)}_${photo.split('/').at(-1)}`
      $(this.document)
        .find('ul.images')
        .append(
                    `<li><img src="${photo}" style="background-image: url(${photo})" data-name="${name}" /></li>`
        )
      $(this.document)
        .find('ul.thumbnails')
        .append(`<li style="background-image: url('${photo}');"></li>`)
    })

    $('body').append(this.document)
    this.setTriggers()

    if (APP.device.type === 'desktop') {
      $('body').attr({
        'data-hamburger-fullscreen': 'true'
      })
    }

    let title = poi.photos[index].split('/').at(-1).split('.')[0]
    if (String(parseInt(Number(title))).length === 10) {
      title = formatDate(new Date(Number(title) * 1000), 'frenchDateTime')
    } else {
      title = ''
    }
    $(this.document).find('div.title p').html(title)

    this.setActiveThumbnail(index)
  }

  isOpen = () => {
    return $('body').has(this.document).length > 0
  }

  close = () => {
    $('body').removeAttr('data-hamburger-fullscreen')
    if (this.isOpen()) {
      $(this.document).remove()
      return true
    }
    return false
  }

  goToIndex = (index) => {
    $(this.document).css({ '--data-index': index })
    this.setActiveThumbnail(index)

    let title = $(this.document).find('ul.images li img').eq(index).attr('src').split('/').at(-1).split('.')[0]
    if (String(parseInt(Number(title))).length === 10) {
      title = formatDate(new Date(Number(title) * 1000), 'frenchDateTime')
    } else {
      title = ''
    }

    $(this.document).find('div.title p').html(title)

    $(this.document)
      .find('ul.images li img')
      .each((i, element) => {
        $(element)
          .css({
            transform: 'matrix(1, 0, 0, 1, 0, 0)'
          })
          .attr({ 'data-start-matrix': 'matrix(1, 0, 0, 1, 0, 0)' })
      })
  }

  next = () => {
    let index = parseInt($(this.document).css('--data-index')) + 1
    const count = $(this.document).find('ul.images').children().length - 1
    if (index > count) {
      index = 0
    }
    this.goToIndex(index)
  }

  previous = () => {
    let index = parseInt($(this.document).css('--data-index')) - 1
    const count = $(this.document).find('ul.images').children().length - 1
    if (index < 0) {
      index = count
    }
    this.goToIndex(index)
  }

  setActiveThumbnail = (index) => {
    $(this.document).find('ul.thumbnails li').removeClass('active')
    const element = $(this.document).find('ul.thumbnails li').eq(index)
    $(element).addClass('active')

    // Scroll container to element
    const container = $(this.document).find('ul.thumbnails')
    const containerPaddingValue = parseInt(container.css('padding-left'))
    const containerScrollValue = parseInt(container.scrollLeft())
    const containerWidth = parseInt(container.css('width'))
    const containerMaxScroll =
            $(container)[0].scrollWidth - $(container).outerWidth()
    const itemScrollPosition =
            parseInt($(element).position().left) -
            parseInt($(element).parent().position().left) -
            containerPaddingValue
    const itemWidth = parseInt($(element).css('width'))
    const scrollValue = Math.min(
      containerMaxScroll,
      Math.max(
        0,
        containerScrollValue +
                    itemScrollPosition -
                    containerWidth / 2 +
                    itemWidth / 2
      )
    )

    container.stop().animate({ scrollLeft: scrollValue }, 500)
  }

  downloadCurrent = () => {
    const index = parseInt($(this.document).css('--data-index'))
    const currentImageSrc = $(this.document)
      .find('ul.images li > img')
      .eq(index)
      .attr('src')
    const currentImageName = $(this.document)
      .find('ul.images li > img')
      .eq(index)
      .attr('data-name')
    download.image({
      url: currentImageSrc,
      name: currentImageName
    })
  }
}
