import $ from 'jquery'
import L from 'leaflet'
import { APP } from '.'
import Track from './map/Track'
import { tileCoordsToBounds } from './tools'
import Location from './map/Location'

export default class Map extends L.Map {
  constructor () {
    super('map', {
      center: APP.state.center,
      zoom: APP.state.zoom,
      tap: false,
      maxZoom: APP.data.settings.map.maxZoom
    })
      .on('zoomstart', () => {
        $(this.getContainer()).addClass('zooming')
        APP.ui.buttons.closeAllsubMenus()
      })
      .on('zoomend', () => {
        $(this.getContainer()).removeClass('zooming')
        APP.ui.buttons.closeAllsubMenus()
      })
      .on('movestart', () => {
        APP.ui.buttons.closeAllsubMenus()
      })
      .on('moveend', () => {
        APP.state.center = this.getCenter()
        APP.urlHandler.update()
        APP.state.updateFromUI()
        this.pipette()
      })
      .on('zoomend', () => {
        APP.state.zoom = this.getZoom()
        APP.state.center.lat = this.getCenter().lat
        APP.state.center.lng = this.getCenter().lng
        APP.ui.update()
        APP.urlHandler.update()
        APP.state.updateFromUI()
        this.pipette()
      })
      .on('move', (event) => {
        this.cursor.setLatLng(this.getCenter())
      })

    this.resetClickEvent()

    this.location = new Location(this)

    this.cursor = new L.Marker(this.getCenter(), {
      icon: new L.DivIcon({
        className: 'icon aim',
        html: '<div class="aim"><div></div><div></div><div></div><div></div></div>'
      })
    })
    this.addLayer(this.cursor)
  }

  update = () => {
    this.switchBackgroundLayer(APP.state.backgroundLayer)
    this.switchSnowLayer(APP.state.snowLayer)
    this.switchPOIlayer(APP.state.POIlayer)
    APP.urlHandler.update()
    this.location.update()
  }

  resetClickEvent = () => {
    this.off('click').on('click', (e) => {
      this.clicked(e)
    })
  }

  clicked = (event) => {
    APP.ui.buttons.closeAllsubMenus()

    // Check that track isn't on
    const selection = this.getSelected()
    if (
      selection instanceof Track &&
            ['started', 'edited'].includes(selection.mode)
    ) {
      return null
    } else if ('select' in event) {
      this.deselectAll()
      event.select()
    } else if ('target' in event) {
      if (
        $(event.originalEvent.target).is(
          'div.leaflet-container, div.leaflet-pane'
        )
      ) {
        this.deselectAll()
      }
    }
  }

  switchBackgroundLayer = (name) => {
    Object.values(APP.backgroundLayers).forEach((item) => {
      if (item.name !== name) {
        item.layer.remove()
      } else {
        item.layer.addTo(this)
      }
    })
  }

  switchSnowLayer = (name) => {
    // Remove all other layers
    Object.values(APP.snowLayers).forEach((item) => {
      if (item.name !== name) {
        this.removeLayer(item.layer)
      }
    })

    // Add requested layer
    Object.values(APP.snowLayers).forEach((item) => {
      if (item.name === name) {
        this.addLayer(item.layer)
      }
    })
  }

  switchPOIlayer = (name) => {
    // Remove all other layers
    Object.values(APP.POIlayers).forEach((item) => {
      if (item.name !== name) {
        this.removeLayer(item.layer)
      }
    })

    // Add requested layer
    Object.values(APP.POIlayers).forEach((item) => {
      if (item.name === name) {
        this.addLayer(item.layer)
      }
    })
  }

  pipette = () => {
    if (APP.state.snowLayer == null) {
      return
    }

    const layer = Object.values(
      APP.snowLayers[APP.state.snowLayer].layer._layers
    )[0]
    const mapCenter = this.getCenter()
    for (const tile of Object.values(layer._tiles)) {
      const bounds = tileCoordsToBounds(tile.coords)
      if (bounds.contains(mapCenter)) {
        // Get % coordinates of map center on tile
        const lngSize = bounds.getEast() - bounds.getWest()
        const latSize = bounds.getNorth() - bounds.getSouth()
        const lngDist = mapCenter.lng - bounds.getWest()
        const latDist = bounds.getNorth() - mapCenter.lat
        let x = lngDist / lngSize
        let y = latDist / latSize

        // Get pixel coordinates
        const img = tile.el
        x = parseInt(img.width * x)
        y = parseInt(img.height * y)

        // Get pixel color
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        // Sample pixel color
        let r, g, b
        try {
          canvas
            .getContext('2d')
            .drawImage(img, 0, 0, img.width, img.height);
          [r, g, b] = canvas
            .getContext('2d')
            .getImageData(x, y, 1, 1).data
          canvas.remove()
        } catch {
          return
        }

        // Convert color and update UI
        const text = APP.colors[APP.state.snowLayer].color2text([
          r,
          g,
          b
        ])

        APP.ui.mapInfo.updateItem('snow', text, {
          color: {
            r,
            g,
            b
          }
        })
      }
    }
  }

  deselectRegions = () => {
    Object.values(APP.regions).forEach((region) => {
      region.deselect()
    })
  }

  deselectPOIs = () => {
    Object.values(APP.POIlayers).forEach((POIlayer) => {
      POIlayer.deselectAll()
    })
  }

  deselectTracks = () => {
    APP.tracks.forEach((track) => {
      track.deselect()
    })
  }

  deselectAll = () => {
    this.deselectRegions()
    this.deselectPOIs()
    this.deselectTracks()
    APP.ui.tooltip.close()
  }

  getSelected = () => {
    // Tracks
    for (const track of Object.values(APP.tracks)) {
      if (track.selected) {
        return track
      }
    }

    // Regions
    for (const region of Object.values(APP.regions)) {
      if (region.selected) {
        return region
      }
    }

    // POI
    for (const POIlayer of Object.values(APP.POIlayers)) {
      for (const poi of Object.values(POIlayer.list)) {
        if (poi.selected) {
          return poi
        }
      }
    }

    return null
  }
}
