import $ from 'jquery'
import DOMelement from './DOMelement'
import getCookie from '@/utils/getCookie'
import setCookie from '@/utils/setCookie'

export default class Settings extends DOMelement {
  constructor () {
    super('tabs/settings.html')

    Promise.all(this.promises).then(() => {
      this.setTriggers()

      if (getCookie('privacy') === 'accepted') {
        $(this.document)
          .find('section.cookies')
          .removeClass('refused')
          .addClass('accepted')
      }

      if (getCookie('privacy') === 'refused') {
        $(this.document)
          .find('section.cookies')
          .removeClass('accepted')
          .addClass('refused')
      }
    })
  }

  setTriggers = () => {
    $(this.document)
      .find('li.reset')
      .on('click', async () => {
        // Unregister all service workers
        if ('serviceWorker' in navigator) {
          await navigator.serviceWorker
            .getRegistrations()
            .then(async (registrations) => {
              for (const registration of registrations) {
                await registration.unregister()
              }
            })
        }

        window.location.reload(true)
      })

    $(this.document)
      .find('li.accept')
      .off()
      .on('click', () => {
        setCookie('privacy', 'accepted')
        window.location.reload()
      })

    $(this.document)
      .find('li.refuse')
      .off()
      .on('click', () => {
        setCookie('privacy', 'refused')
        window.location.reload()
      })

    $(this.document)
      .find('.donate')
      .off()
      .on('click', () => {
        window
          .open('https://buy.stripe.com/bIY14pcPd67dcfubIJ', '_blank')
          .focus()
      })

    $(this.document)
      .find('.shop')
      .off()
      .on('click', () => {
        window.open('/shop', '_blank').focus()
      })

    $(this.document)
      .find('.playstore')
      .off()
      .on('click', () => {
        window.open('https://play.google.com/store/apps/details?id=fr.snowmap', '_blank').focus()
      })

    $(this.document)
      .find('.facebook')
      .off()
      .on('click', () => {
        window.open('https://www.facebook.com/snowmapofficial', '_blank').focus()
      })

    $(this.document)
      .find('.instagram')
      .off()
      .on('click', () => {
        window.open('https://www.instagram.com/snowmapofficial/', '_blank').focus()
      })

    $(this.document)
      .find('.email')
      .off()
      .on('click', () => {
        window.open('mailto:snowmapofficial@gmail.com', '_blank').focus()
      })
  }
}
