import { APP } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'

export default class Tooltip extends DOMelement {
  constructor () {
    super('tooltip.html')
    $('body').attr({ 'data-tooltip': 'closed' })
  }

  open = (target) => {
    this.reset()
    $(this.document).attr({ 'data-name': target.name })
    $(this.document).removeAttr('data-hidden')
    $('body').attr({ 'data-tooltip': 'open' })

    Promise.all(target.promises).then(() => {
      if (target.name.toString() === $(this.document).attr('data-name')) {
        $(this.document).find('span.name').html(target.title)
        $(this.document)
          .removeAttr('data-hidden data-loading')
          .attr({ 'data-family': target.family })
          .on('click', () => {
            APP.ui.buttons.closeAllsubMenus()
            APP.map.getSelected().openArticle()
          })
      }
    })
  }

  close = () => {
    $('body').attr({ 'data-tooltip': 'closed' })
    $(this.document)
      .attr({ 'data-hidden': 'true' })
      .removeAttr('data-loading')
  }

  isOpen = () => {
    return !$(this.document).is('[data-hidden="true"]')
  }

  reset = () => {
    $(this.document)
      .removeAttr('data-family data-name data-hidden')
      .attr({
        'data-loading': 'true'
      })
      .off('click')
  }

  setTriggers = () => {
    $(this.document)
      .find('button.close')
      .on('click', (event) => {
        event.stopPropagation()
        this.close()
        APP.map.deselectAll()
        APP.ui.buttons.closeAllsubMenus()
      })
  }
}
