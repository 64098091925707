import { APP } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'
import svgFiles from './svgFiles'
import exportData from '@/utils/exportData'

export default class Bookmarks extends DOMelement {
  constructor () {
    super('tabs/bookmarks.html')

    const list = [
      'tracks',
      'topos',
      'regions',
      'sorties',
      'webcams',
      'stations',
      'refuges'
    ]
    for (const item in list) {
      list[item] = new DOMelement(`tabs/bookmarks_pages/${list[item]}.html`
      )
      this.promises = this.promises.concat(list[item].promises)
    }

    Promise.all(this.promises).then(() => {
      list.forEach((item) => {
        $(this.document).append(item.document)
      })

      this.updateRoot()
      this.setPage('root')
    })
  }

  setTriggers = () => {
    $(this.document)
      .find('ul.categories li')
      .off()
      .on('click', (event) => {
        const name = $(event.currentTarget).attr('class')
        this.updatePage(name)
        this.setPage(name)
      })

    $(this.document)
      .find('li.export_my_data')
      .off()
      .on('click', async () => {
        exportData()
      })

    $(this.document)
      .find('input[type="file"]')
      .off()
      .on('change', (event) => {
        APP.data.fileReader.readAsText(event.target.files[0])
      })
  }

  setPage = (name) => {
    const response = $(this.document).is(`[data-mode="${name}"]`)
    $(this.document).attr({ 'data-mode': name })
    return !response
  }

  update = () => {
    this.updateRoot()
    $(this.document)
      .find('article:not(.root)')
      .each((_, item) => {
        this.updatePage($(item).attr('class'))
      })
  }

  updateRoot = () => {
    $(this.document)
      .find('article.root ul.categories li')
      .each((_, item) => {
        const data = localStorage.getItem($(item).attr('class'))
        if (data) {
          const length = JSON.parse(data).length
          let text = `${length} élément`
          if (length > 1) {
            text += 's'
          }
          $(item).find('span.info').html(text)
          $(item).css({ display: '' })
          if (length === 0) {
            $(item).css({ display: 'none' })
          }
        } else {
          $(item).css({ display: 'none' })
        }
      })
  }

  updatePage = (name) => {
    // Reset document
    $(this.document)
      .find(`article.${name} > section > ul > li:not(.blank)`)
      .remove()

    let list

    if (name === 'tracks') {
      list = APP.tracks
    } else {
      list = JSON.parse(localStorage.getItem(name))
      if (!list) {
        return
      }
    }

    if (name === 'regions') {
      // Reset document
      $(this.document).find(`article.${name} > section`).remove()

      list.forEach((item) => {
        if (!(item in APP.regions)) {
          delete list[list.indexOf(item)]

          // Refresh list
          localStorage.setItem(name, JSON.stringify(list))
        } else {
          $(this.document)
            .find(`article.${name}`)
            .append(
              APP.ui.reportsList.buildSingleReport(
                APP.regions[item]
              )
            )
        }
      })

      return
    }

    list.forEach((item) => {
      let poi
      let promise
      if (
        ['refuges', 'topos', 'sorties', 'webcams', 'stations'].includes(
          name
        )
      ) {
        if (!(item in APP.POIlayers[name].list)) {
          delete list[list.indexOf(item)]

          // Refresh list
          localStorage.setItem(name, JSON.stringify(list))

          return
        } else {
          poi = APP.POIlayers[name].list[item]
          promise = poi.getDetails()
        }
      } else if (name === 'tracks') {
        poi = item
      }

      Promise.resolve(promise).then(() => {
        const element = $(this.document)
          .find(`article.${name} > section > ul > li.blank`)
          .clone()
          .removeClass('blank')
          .appendTo(
            $(this.document).find(`article.${name} > section > ul`)
          )

        // Title
        $(element).find('p').html(poi.title)

        // Triggers
        $(element)
          .off()
          .on('click', (event) => {
            if (
              !$(event.target)
                .parents('ul')
                .first()
                .is('.actions')
            ) {
              poi.article.open()
            }
          })

        if (name === 'tracks') {
          // Checkbox
          const visible = APP.map.hasLayer(item.featureGroup)
          $(element)
            .find('input[type="checkbox"]')
            .prop('checked', visible)
            .attr({ id: `track_${item.name}` })
            .off()
            .on('change', (event) => {
              item.toggleOnMap($(event.target).is(':checked'))
            })
            .siblings('label')
            .attr({ for: `track_${item.name}` })

          $(element)
            .find('ul.actions li')
            .off()
            .on('click', (event) => {
              const action = $(event.currentTarget).attr(
                'data-action'
              )
              if (action) {
                item[action]()
              }
            })
        }

        // Data
        const dataList = $(element).find('ul.data')
        Object.values(poi.parent.attributes).forEach((attribute) => {
          if (
            attribute.name in poi &&
                        poi[attribute.name] != null &&
                        !isNaN(poi[attribute.name]) &&
                        attribute.in_data_list
          ) {
            const value = poi[attribute.name]
            const li = $(dataList)
              .find('li.blank')
              .clone()
              .removeAttr('class')
              .attr({ 'data-name': attribute.name })
              .appendTo(dataList)

            const svg = svgFiles[attribute.name]
            $(li)
              .find('img')
              .attr({
                src: 'data:image/svg+xml;base64,' + btoa(svg)
              })

            let cssClass = ''
            if (value === 0) {
              cssClass = 'striken'
            }

            switch (attribute.type) {
              // Named value
              case 'cotation':
                $(li).find('span').html(attribute.data[value])
                break
                // Value, can have a unit
              case 'value':
                if (attribute.data.unit === '' && value === 0) {
                  $(li)
                    .attr({ class: cssClass })
                    .find('span')
                    .remove()
                } else {
                  $(li)
                    .attr({ class: cssClass })
                    .find('span')
                    .html(value + attribute.data.unit)
                }
                break
                // On or Off
              case 'bool':
                $(li)
                  .attr({ class: cssClass })
                  .find('span')
                  .remove()
                break
            }
          }
        })
      })
    })
  }
}
