// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/images/pdf.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/images/phone.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/images/print.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../../assets/images/download.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("../../../assets/images/rating.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("../../../assets/images/logo-camptocamp.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("../../../assets/images/cc.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var code = "<article class=\"topo\"> <h1>Topo</h1> <input type=\"checkbox\" class=\"bookmark\" name=\"bookmark\" id=\"bookmark\"> <label for=\"bookmark\"></label> <section> <h2 class=\"title\"></h2> <h3 class=\"summary\">Résumé</h3> <div class=\"paragraph\" data-name=\"summary\"></div> <ul class=\"photos\"> <li class=\"blank\" data-url=\"\" style=\"background-image:url(../../images/empty.svg)\"></li> <li class=\"moreImages\"><span></span></li> </ul> <ul class=\"actions\"> <li data-action=\"download_pdf\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"PDF\"> <span>Télécharger le topo</span> <input type=\"radio\" id=\"mobile\" name=\"format\" value=\"mobile\" checked=\"\"> <label for=\"mobile\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"Mobile\"></label> <input type=\"radio\" id=\"paper\" name=\"format\" value=\"paper\"> <label for=\"paper\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"Papier\"></label> </li> <li data-action=\"download_geojson\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"Télécharger\"> <span>Télécharger la trace GPS</span> </li> <li data-action=\"save_geojson\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"Modifier\"> <span>Sauvegarder la trace GPS</span> </li> </ul> <h3 class=\"remarks\">Remarques</h3> <div class=\"paragraph\" data-name=\"remarks\"></div> <h3 class=\"description\">Description</h3> <div class=\"paragraph\" data-name=\"description\"></div> <p class=\"links\"> <a href=\"https://www.camptocamp.org/\" class=\"external\" target=\"_blank\" data-site=\"c2c\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\"> Camptocamp.org </a> <a href=\"http://creativecommons.org/licenses/by-sa/3.0/deed.fr\" class=\"external\" target=\"_blank\" data-license=\"cc-by-sa\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\"> BY-SA </a> </p> </section> </article>";
// Exports
export default code;