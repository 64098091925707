import { APP } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'
import Track from '@/map/Track'

export default class MapButtons extends DOMelement {
  constructor () {
    super('map-buttons.html')
  }

  setTriggers = () => {
    $(this.document)
      .find('button')
      .on('click', (event) => {
        this.buttonClicked(event)
      })
  }

  getState = async () => {
    return Promise.all(this.promises).then(() => {
      return {
        backgroundLayer: $(this.document)
          .find('button[data-group="backgroundLayer"].selected')
          .attr('data-action'),
        snowLayer: $(this.document)
          .find('button[data-group="snowLayer"].selected')
          .attr('data-action'),
        POIlayer: $(this.document)
          .find('button[data-group="poi"].selected')
          .attr('data-action'),
        locationEnabled: $(this.document)
          .find('button[data-group="location"]')
          .is('.selected')
      }
    })
  }

  update = async () => {
    return Promise.all(this.promises).then(() => {
      $(this.document).find('button').removeClass('selected')
      $(this.document)
        .find(
                    `button[data-group="backgroundLayer"][data-action="${APP.state.backgroundLayer}"]`
        )
        .addClass('selected')
      $(this.document)
        .find(
                    `button[data-group="snowLayer"][data-action="${APP.state.snowLayer}"]`
        )
        .addClass('selected')
      $(this.document)
        .find(
                    `button[data-group="poi"][data-action="${APP.state.POIlayer}"]`
        )
        .addClass('selected')

      if (APP.state.locationEnabled) {
        $(this.document)
          .find('button[data-group="location"][data-action="toggle"]')
          .addClass('selected')
      } else {
        $(this.document)
          .find('button[data-group="location"][data-action="toggle"]')
          .removeClass('selected')
      }
    })
  }

  // Handles button click events depending on button type
  buttonClicked = (event) => {
    const target = event.currentTarget
    const type = target.dataset.type
    const group = target.dataset.group
    const action = target.dataset.action

    // Filters button
    if ($(target).hasClass('filtersMenuButton')) {
      if (APP.state.POIlayer) {
        APP.POIlayers[APP.state.POIlayer].filterArticle.open()
      }
      return
    }

    // Close all menus if the clicked element isn't part of the one which is currently open
    const openGroup = $(this.document)
      .find('ul.subMenu.selected > li > button')
      .first()
    if (openGroup.length > 0 && openGroup[0].dataset.group !== group) {
      this.closeAllsubMenus()
    }

    // UI states
    switch (type) {
      case 'switch':
        $(target).addClass('selected')
        $(this.document)
          .find(`button[data-type=${type}][data-group=${group}]`)
          .not(target)
          .removeClass('selected')
        break
      case 'toggle':
        $(target).toggleClass('selected')
        break
      case 'toggle-switch':
        $(target).toggleClass('selected')
        $(this.document)
          .find(`button[data-type=${type}][data-group=${group}]`)
          .not(target)
          .removeClass('selected')
        break
      case 'subMenu':
        $(this.document)
          .find(`ul.subMenu[data-group="${group}"]`)
          .toggleClass('selected')
        $(this.document)
          .find('ul.subMenu[data-group]')
          .not(`[data-group="${group}"]`)
          .removeClass('selected')
        break
    }

    // Update UI and map
    if (type !== 'subMenu') {
      APP.state.updateFromUI().then(() => {
        APP.ui.update()
        APP.map.update()
      })
    }

    // Track buttons
    if ($(target).is('[data-group="draw"]')) {
      const selection = APP.map.getSelected()
      if (action === 'start') {
        APP.tracks.push(new Track())
      } else if (selection instanceof Track) {
        selection[action]()
      }
    }

    // Center to loc button
    if ($(target).is('[data-group="location"][data-action="center"]')) {
      APP.map.location.center()
    }

    // Zoom button
    if ($(target).is('[data-group="zoom"]')) {
      if ($(target).is('[data-action="zoom-in"]')) {
        APP.map.setZoom(APP.map.getZoom() + 1)
      }
      if ($(target).is('[data-action="zoom-out"]')) {
        APP.map.setZoom(APP.map.getZoom() - 1)
      }
    }
  }

  closeAllsubMenus = () => {
    $(this.document).find('ul.subMenu').removeClass('selected')
  }
}
