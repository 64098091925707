/* eslint-disable camelcase */

import compass from '../../assets/images/compass.svg'
import map from '../../assets/images/map.svg'
import report from '../../assets/images/report.svg'
import rating from '../../assets/images/rating.svg'
import settings from '../../assets/images/settings.svg'
import diff_asc from '../../assets/images/diff_asc.svg'
import diff_ski from '../../assets/images/diff_ski.svg'
import diff_ski_section from '../../assets/images/diff_ski_section.svg'
import exposure from '../../assets/images/exposure.svg'
import avalanche_risk from '../../assets/images/avalanche_risk.svg'
import min_elevation from '../../assets/images/min_elevation.svg'
import max_elevation from '../../assets/images/max_elevation.svg'
import elevation_up from '../../assets/images/elevation_up.svg'
import mattresses from '../../assets/images/mattresses.svg'
import blankets from '../../assets/images/blankets.svg'
import heating from '../../assets/images/heating.svg'
import locked from '../../assets/images/locked.svg'
import elevation_down from '../../assets/images/elevation_down.svg'
import distance from '../../assets/images/distance.svg'
import elevation from '../../assets/images/elevation.svg'
import fov from '../../assets/images/fov.svg'
import orientation from '../../assets/images/orientation.svg'
import gas from '../../assets/images/gas.svg'
import has_geojson from '../../assets/images/has_geojson.svg'
import view_angle from '../../assets/images/view_angle.svg'
import track_graph from '../../assets/images/track_graph.svg'
import snow_cover from '../../assets/images/snow_cover.svg'
import sorties from '../../assets/images/sortie.svg'

export default {
  sorties,
  compass,
  map,
  report,
  rating,
  settings,
  diff_asc,
  diff_ski,
  diff_ski_section,
  exposure,
  avalanche_risk,
  min_elevation,
  max_elevation,
  elevation_up,
  mattresses,
  blankets,
  heating,
  locked,
  elevation_down,
  distance,
  elevation,
  fov,
  orientation,
  gas,
  has_geojson,
  view_angle,
  track_graph,
  snow_cover
}
