// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../assets/images/snowmap.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../assets/images/clock.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../assets/images/validate.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"update-window\"> <div class=\"disclaimer\"> <div class=\"scroll\"> <h1> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> Mise à jour </h1> <p>Une mise à jour est disponible, souhaitez-vous l'installer ?</p> </div> <div class=\"actions\"> <button class=\"later\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> Plus tard </button> <button class=\"install\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> Installer </button> </div> </div> </div>";
// Exports
export default code;