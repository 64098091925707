import $ from 'jquery'

export default class Device {
  constructor () {
    [this.type, this.width, this.height] = Device.detect()
  }

  static detect = () => {
    let device = 'desktop'
    if ($(window).width() < $(window).height()) {
      device = 'mobile'
    }

    $('html').addClass(device)

    // Lock device width/height
    // if (device === 'mobile') {
    //   setTimeout(() => {
    //     $('html').css({
    //       width: $(window).width(),
    //       height: $(window).height()
    //     })
    //   }, 500)
    // }

    return [device, $(window).width(), $(window).height()]
  }
}
