import proj4 from 'proj4'
import JsPDF from 'jspdf'
import inside from '@turf/inside'
import L from 'leaflet'
import BBCode from './utils/BBCode'
import Showdown from 'showdown'
import $ from 'jquery'
import { APP } from '.'

export const translate = (name) => {
  if (!(name in APP.data.translations)) {
    console.warn(`'${name}' does not have a translation.`)
    return name
  } else {
    return APP.data.translations[name].french
  }
}

export const uri = (string) => {
  // Convert encoded characters
  string = $('<textarea />').html(string).text()

  string = string
    .replaceAll(/\s/g, '-')
    .replaceAll(/&[0-9a-zA-Z]*;/g, '-')
    .toLowerCase()
    .normalize('NFD')
    .replaceAll(/[\u0300-\u036f]/g, '')
    .replaceAll(/[^0-9a-zA-Z\-_]/g, '-')
    .replaceAll(' ', '')

  // Remove double --
  while (string.includes('--')) {
    string = string.replaceAll('--', '-')
  }

  // Remove first -
  if (string[0] === '-') {
    string = string.substring(1)
  }

  // Remove last -
  if (string.at(-1) === '-') {
    string = string.substring(0, string.length - 1)
  }

  return string
}

export const formatDate = function (date, mode) {
  const mois = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
  const moisShort = [
    'Jan.',
    'Fév.',
    'Mar.',
    'Avr.',
    'Mai',
    'Juin',
    'Juil.',
    'Aoû.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Déc.'
  ]
  const jours = [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi'
  ]

  const offset = date.getTimezoneOffset()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDay() + 1).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  switch (mode) {
    case 'yyyy-mm-dd':
      date = new Date(date.getTime() - offset * 60 * 1000)
      return date.toISOString().split('T')[0]
    case 'yyyy-mm-dd_hh-mm':
      return `${year}-${month}-${day}_${hours}h${minutes}`
    case 'frenchDateTime':
      return `${jours[date.getDay()]} ${date.getDate()} ${
                mois[date.getMonth()]
            } ${date.getFullYear()} à ${date.getHours()}h${String(
                date.getMinutes()
            ).padStart(2, '0')}`
    case 'frenchDate':
      return `${jours[date.getDay()]} ${date.getDate()} ${
                mois[date.getMonth()]
            } ${date.getFullYear()}`
    case 'shortFrenchDate':
      return `${jours[date.getDay()][0]}. ${date.getDate()} ${moisShort[
                date.getMonth()
            ].toLowerCase()}`
    case 'hour':
      return String(date.getHours()) + 'h'
  }
}

export const parseText = (text) => {
  // Remove inline images
  const regex = /\[img=(.*?)\](.*?)\[\/img\]/g
  text = text.replaceAll(regex, '')

  // Replace new lines
  text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')

  return text
}

export const markdown = (text) => {
  if (text == null) {
    return text
  }

  // Convert Markup to HTML
  const converter = new Showdown.Converter()
  converter.setOption('simpleLineBreaks', true)
  text = converter.makeHtml(text)

  // Get links videos and inline images
  const regexTable = [
    {
      regex: /\[video\](.*?)\[\/video\]/g,
      name: 'video'
    },
    {
      regex: /\[\[(.*?)\|(.*?)\]\]/g,
      name: 'url'
    },
    {
      regex: /\[img(.*?)\](.*?)\[\/img\]/gms,
      name: 'image'
    }
  ]

  regexTable.forEach(function (item) {
    let detected = item.regex.exec(text)
    while (detected != null) {
      switch (item.name) {
        case 'video':
          text = text.replaceAll(
            detected[0],
            '<a href="' +
            detected[1] +
            '" target="_blank" class="video-link external"><img src="../images/video.svg" alt="Vid&eacuteo"><span>Vid&eacute;o<span></a>'
          )
          break
        case 'url':
          text = text.replaceAll(
            detected[0],
            '<a href="https://www.camptocamp.org/' +
            detected[1] +
            '" target="_blank">' +
            detected[2] +
            '</a>'
          )
          break
        case 'image':
          text = text.replaceAll(detected[0], '')
          break
      }
      detected = item.regex.exec(text)
    }
  })

  return text
}

export const bbcode = (text) => {
  text = parseText(text)

  // Convert BBCode to HTML
  text = '<p>' + new BBCode().parse(text) + '</p>'
  return text
}
export const toNumber = (string) => {
  if (!string) {
    string = '0'
  }
  const number = Number(string.replace(/[^0-9.]/g, ''))
  return number
}

export const download = {}
download.textFile = ({ text, filename, contentType } = {}) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
        `data:${contentType}; charset=utf-8,` + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

download.image = ({ url, name } = {}) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
}

export const isMarkerInsidePolygon_ = (point, poly) => {
  const polyPoints = poly.getLatLngs()[0]
  const x = point.x
  const y = point.y

  let inside = false
  for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    const xi = polyPoints[i].lat
    const yi = polyPoints[i].lng
    const xj = polyPoints[j].lat
    const yj = polyPoints[j].lng

    const intersect =
            (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

export const pointInRegion = (coordinates) => {
  for (const region of Object.values(APP.regions)) {
    let polygon
    region.layer.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        polygon = layer.toGeoJSON()
      }
    })

    if (pointInPolygon(coordinates, polygon)) {
      return region
    }
  }
}

export const pointInPolygon = (coordinates, polygon) => {
  const point = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates
    }
  }

  const val = inside(point, polygon)

  return val
}

export const rgb2hsv = ([r, g, b]) => {
  // H in degrees over 360, S and V in percents
  [r, g, b] = [r / 255, g / 255, b / 255]

  const v = Math.max(r, g, b)
  const c = v - Math.min(r, g, b)
  let h =
        c &&
        (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c)
  h = 60 * (h < 0 ? h + 6 : h)
  const s = v && c / v

  return [parseInt(h), parseInt(s * 100), parseInt(v)]
}

export const hex2rgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const value = result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null
  return value
}

export const hex2hsv = (hex) => {
  return rgb2hsv(hex2rgb(hex))
}

export const rgb2hex = ([r, g, b]) => {
  const value =
        '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)
  return value
}

export const html2pdf = ({ html, name, mode } = {}) => {
  let format, width, margin, factor

  switch (mode) {
    case 'mobile':
      format = [3000, 210 / 2]
      width = 190 / 2
      margin = 10 / 4
      factor = 2.5
      break
    case 'print':
      format = [297, 210]
      width = 190
      margin = 10
      factor = 2.5
      break
    default:
      throw Error(`Unknown mode: ${mode}`)
  }

  const doc = new JsPDF('p', 'mm', format)

  $(html).addClass('print')
  $('body').append(html)

  doc.html(html[0], {
    x: 0,
    y: 0,
    width,
    margin: [margin * 2, margin, margin * 2, margin],
    windowWidth: width * factor,
    callback: (doc) => {
      doc.save(name)
    }
  })

  $(html).remove()
}

export const removeHTML = (string) => {
  // Create a temporary div element to parse the input as HTML
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = string

  // Extract the text content without HTML tags
  const textContent = tempDiv.textContent || tempDiv.innerText

  return textContent
}

export const convertCRS = (from, to, latLng) => {
  const projections = {
    2056: {
      proj: '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs',
      reversed: false
    },
    4326: {
      proj: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs',
      reversed: false
    }
  }

  if (!(from in projections)) {
    throw Error(`Cannot project from unknown projection '${from}'.`)
  }
  if (!(to in projections)) {
    throw Error(`Cannot project to unknown projection '${to}'.`)
  }

  if (projections[to].reversed) {
    latLng = latLng.reverse()
  }

  latLng = proj4(
    new proj4.Proj(projections[from].proj),
    new proj4.Proj(projections[to].proj),
    latLng
  )

  if (projections[from].reversed) {
    latLng = latLng.reverse()
  }

  return latLng
}

export const xml2js = (xml) => {
  const parser = new DOMParser()
  xml = parser.parseFromString(xml, 'text/xml')

  let obj = {}

  if (xml.nodeType === 1) {
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {}
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j)
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue
      }
    }
  } else if (xml.nodeType === 3) {
    obj = xml.nodeValue.trim()
  }

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i)
      const nodeName = item.nodeName

      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xml2js(item)
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          const old = obj[nodeName]
          obj[nodeName] = []
          obj[nodeName].push(old)
        }
        obj[nodeName].push(xml2js(item))
      }
    }
  }
  return obj
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const sortObjectsByCloseness = (inputName, objectList) => {
  // Create a custom sorting function that calculates the Levenshtein distance
  // between the inputName and the 'name' property of each object.
  const customSort = (a, b) => {
    const distanceA = calculateLevenshteinDistance(
      inputName,
      a.properties.name
    )
    const distanceB = calculateLevenshteinDistance(
      inputName,
      b.properties.name
    )

    return distanceA - distanceB
  }

  // Sort the objectList using the custom sorting function.
  const sortedObjects = [...objectList].sort(customSort)

  return sortedObjects
}

export const calculateLevenshteinDistance = (a, b) => {
  if (a.length === 0) return b.length
  if (b.length === 0) return a.length

  const matrix = []

  // Initialize the matrix.
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }

  // Fill in the matrix.
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      const cost = a[j - 1] === b[i - 1] ? 0 : 1
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // Deletion
        matrix[i][j - 1] + 1, // Insertion
        matrix[i - 1][j - 1] + cost // Substitution
      )
    }
  }

  return matrix[b.length][a.length]
}

export const retry = async ({ url, headers = {}, maxRetries } = {}) => {
  let retries = 1
  while (true) {
    const promise = fetch(url, {
      headers
    })
    let success = false
    const response = await Promise.resolve(promise)
      .then(async (response) => {
        success = true
        return response.json().then((response) => {
          return response
        })
      })
      .catch(() => {
        success = false
      })
    if (success) {
      return response
    } else if (retries >= maxRetries) {
      return Promise.reject(
        new Error(`Request failed after ${retries} retries`)
      )
    } else {
      retries += 1
      console.warn(
                `Request to ${
                    url.split('/')[2]
                } failed. Retrying (${retries}/${maxRetries})...`
      )
    }
  }
}

export const tileCoordsToBounds = (tileCoords) => {
  const gridLayer = L.gridLayer()
  gridLayer._map = APP.map
  const bounds = gridLayer._tileCoordsToBounds(tileCoords)
  return bounds
}
