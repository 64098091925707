import { APP } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'

export default class Breadcrumbs extends DOMelement {
  constructor () {
    super('breadcrumbs.html')
  }

  update = async () => {
    return Promise.all(this.promises).then(() => {
      this.reset()

      // Background layer
      this.addButton({
        name: APP.backgroundLayers[APP.state.backgroundLayer].name,
        title: APP.backgroundLayers[APP.state.backgroundLayer].title,
        family: 'backgroundLayer',
        removeable: ''
      })

      // Snow layer
      this.addButton({
        name: APP.snowLayers[APP.state.snowLayer]?.name,
        title: APP.snowLayers[APP.state.snowLayer]?.title,
        family: 'snowLayer'
      })

      // POI layer
      this.addButton({
        name: APP.POIlayers[APP.state.POIlayer]?.name,
        title: APP.POIlayers[APP.state.POIlayer]?.title,
        family: 'POIlayer'
      })
    })
  }

  reset = () => {
    $(this.document).find('li').remove()
  }

  addButton = ({ name, title, family, removeable = 'removeable' } = {}) => {
    if (name == null) {
      return null
    }

    $(this.document)
      .append('<li></li>')
      .find('li:not([data-family])')
      .attr({
        'data-action': name,
        'data-family': family,
        class: removeable
      })
      .html(title)
      .on('click', (event) => {
        this.buttonClicked(event.target)
      })
  }

  buttonClicked = (target) => {
    if ($(target).hasClass('removeable')) {
      $(target).remove()
      APP.state[$(target).attr('data-family')] = null
      APP.map.update()
      APP.ui.update()
    }
    if ($(target).is('[data-family="backgroundLayer"]')) {
      $(
        'div.mapButtons button[data-type="subMenu"][data-group="backgroundLayer"]'
      ).trigger('click')
    }
  }
}
