import $ from 'jquery'
import FormControl from './FormControl'

export default class Checkbox extends FormControl {
  constructor (data) {
    super('checkbox', data)

    Promise.all(this.promises).then(() => {
      this.setValue()
      this.setTriggers()
      this.trigger()
    })
  }

  setValue = () => {
    $(this.document)
      .find('input[type=checkbox]')
      .prop('checked', this.filter.value)
    this.trigger()
  }

  getValue = () => {
    return $(this.document).find('input[type=checkbox]').prop('checked')
  }

  setTriggers = () => {
    $(this.document)
      .find('input[type=checkbox]')
      .off()
      .on('input', (event) => {
        const target = event.currentTarget
        $(target).attr('data-value', $(target).is(':checked'))
        this.updateFilterValue()
      })
  }

  trigger = () => {
    $(this.document).find('input[type=checkbox]').trigger('input')
  }
}
