import $ from 'jquery'
import L from 'leaflet'
import { APP } from '..'
import Article from '@/ui/Article'
import MapObject from './MapObject'
import empty from '../../assets/images/empty.svg'

export default class Region extends MapObject {
  static dummyReport = {
    timestamp: 0,
    avalanche_risk: {
      texts: {},
      aspects: {
        e: false,
        n: false,
        s: false,
        w: false,
        ne: false,
        nw: false,
        se: false,
        sw: false
      },
      max_risk: 0,
      risk_over: [null, null],
      risk_under: [null, null],
      elevation_limit: null
    },
    snow_cover: {
      texts: {},
      aspects: {
        n: {
          0: 0
        },
        s: {
          0: 0
        }
      }
    },
    fresh_snow: {
      value: 0,
      aspects: {
        a: {
          1800: 0
        }
      },
      elevation: 1800
    }
  }

  constructor (data) {
    super({
      name: data.name,
      family: 'regions',
      detailsFetched: true
    })

    this.name = data.name
    this.title = data.title
    this.country = data.country
    this.massif = data.massif
    this.highest_point_elevation = data.highest_point_elevation
    this.highest_point_name = data.highest_point_name
    this.lowest_point_elevation = data.lowest_point_elevation
    this.lowest_point_name = data.lowest_point_name
    this.report = Region.fillSnowValues(data.report)
    this.url = data.url

    this.promises = []
    this.layer = Region.createLayer({
      geojson: data.geojson,
      maxRisk: this.report.avalanche_risk.max_risk,
      instance: this
    })
    this.article = new Article(this)
  }

  static fillSnowValues (report, min = 0, max = 5000, step = 100) {
    // Return dummy report if no report available
    if (report == null) {
      report = Region.dummyReport
    }

    for (const aspect in report.snow_cover.aspects) {
      let n = min
      const result = {}

      while (n <= max) {
        result[n] = Region.interpolate(
          n,
          report.snow_cover.aspects[aspect]
        )
        n += step
      }
      report.snow_cover.aspects[aspect] = result
    }

    return report
  }

  static interpolate (index, keyframes) {
    // Find between which keys the index stands
    const keys = Object.keys(keyframes)
    let ceiling = null
    for (let key in keys) {
      key = parseInt(keys[key])
      if (index <= key) {
        ceiling = key
        break
      }
    }

    let floor = null
    keys.reverse()
    for (let key in keys) {
      key = parseInt(keys[key])
      if (index >= key) {
        floor = key
        break
      }
    }

    if (floor == null) {
      return 0
    } else if (ceiling == null || floor === ceiling) {
      return keyframes[floor]
    } else {
      const ratio = (index - floor) / (ceiling - floor)
      const distanceCeilFloor = keyframes[ceiling] - keyframes[floor]
      return parseInt(distanceCeilFloor * ratio + keyframes[floor])
    }
  }

  static createLayer = ({ geojson, maxRisk, instance } = {}) => {
    /* Creates Leaflet layer for region's limits and avalanche risk icon */

    geojson = L.geoJSON(geojson, {
      parent: instance,
      className: `regions risk_${maxRisk} ${instance.name}`
    })

    const polygon = Object.values(geojson._layers)[0]

    const layer = new L.LayerGroup(
      [
        polygon
          .on('click', () => {
            APP.map.clicked(instance)
          })
          .on('mouseover', (event) => {
            event.target.options.parent.layer.eachLayer((layer) => {
              if (layer instanceof L.Path) {
                $(layer._path).addClass('hover')
                layer.bringToFront()
              }
            })
          })
          .on('mouseout', (event) => {
            event.target.options.parent.layer.eachLayer((layer) => {
              if (layer instanceof L.Path) {
                $(layer._path).removeClass('hover')
              }
            })
          }),
        new L.Marker(polygon.getBounds().getCenter(), {
          title: '',
          alt: '',
          icon: new L.Icon({
            className: `icon regions risque${maxRisk}`,
            iconUrl: 'data:image/svg+xml;base64,' + btoa(empty)
          }),
          parent: instance
        })
          .on('click', () => {
            APP.map.clicked(instance)
          })
          .on('mouseover', (event) => {
            event.target.options.parent.layer.eachLayer((layer) => {
              if (layer instanceof L.Path) {
                $(layer._path).addClass('hover')
                layer.bringToFront()
              }
            })
          })
          .on('mouseout', (event) => {
            event.target.options.parent.layer.eachLayer((layer) => {
              if (layer instanceof L.Path) {
                $(layer._path).removeClass('hover')
              }
            })
          })
      ],
      {
        family: 'regions',
        parent: instance
      }
    )

    layer.eachLayer((layer) => {
      layer.on('add', (event) => {
        if (event.target instanceof L.Polygon) {
          event.target.bringToBack()
        }
      })
    })

    return layer
  }

  select_ = this.select
  select = () => {
    this.select_()

    this.layer.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        $(layer._path).addClass('selected')
      } else if (layer instanceof L.Marker) {
        $(layer._icon).addClass('selected')
      }
    })
    APP.ui.tooltip.open(this)
  }

  deselect_ = this.deselect
  deselect = () => {
    if (this.selected) {
      this.layer.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          $(layer._path).removeClass('selected')
        } else if (layer instanceof L.Marker) {
          $(layer._icon).removeClass('selected')
        }
      })
    }

    this.deselect_()
  }

  updateGeoJSON (geojson) {
    // Reverse lat and lng
    geojson.coordinates[0] = L.GeoJSON.coordsToLatLngs(
      geojson.coordinates[0]
    )

    // Update layer
    this.layer.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.setLatLngs(geojson.coordinates).redraw()
      }
    })
  }
}
