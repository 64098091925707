import L from 'leaflet'
import { APP } from '..'
import { uri } from '@/tools'

export default class UrlHandler {
  constructor () {
    this.state = this.get()

    if (APP.device.type === 'mobile') {
      window.onpopstate = () => {
        this.onpopstate()
      }

      history.pushState({ snowmap: true }, '', '')
    }
  }

  getPosAndZoom = () => {
    if (window.location.pathname.includes('~')) {
      const posAndZoom = window.location.pathname
        .split('~')
        .at(-1)
        .split(',')
        .map((x) => parseFloat(x))
        .filter((x) => !isNaN(x))
      if (posAndZoom.length === 3) {
        return {
          center: {
            lat: posAndZoom[0],
            lng: posAndZoom[1]
          },
          zoom: posAndZoom[2]
        }
      }
    }
    return {
      center: null,
      zoom: null
    }
  }

  getParams = () => {
    return window.location.pathname
      .split('~')
      .at(0)
      .split('/')
      .filter((x) => x !== '')
  }

  getBackgroundLayer = () => {
    const backgroundLayer = this.getParams().filter((x) =>
      Object.keys(APP.backgroundLayers).includes(x)
    )

    // Return first in list if nothing else
    if (backgroundLayer.length === 0) {
      return null
    }

    return backgroundLayer[0]
  }

  getSnowLayer = () => {
    const snowLayer = this.getParams().filter((x) =>
      Object.keys(APP.snowLayers).includes(x)
    )

    if (snowLayer.length === 0) {
      return null
    }

    return snowLayer[0]
  }

  getPOIlayer = () => {
    const POIlayer = this.getParams().filter((x) =>
      Object.keys(APP.POIlayers).includes(x)
    )

    if (POIlayer.length === 0) {
      return null
    }

    return POIlayer[0]
  }

  getFilters = () => {
    if (this.POIlayer == null) {
      return null
    }
    const query = Object.fromEntries(
      new URLSearchParams(window.location.search)
    )

    for (const item in query) {
      if (
        !Object.keys(APP.POIlayers[this.POIlayer].filters).includes(
          item
        )
      ) {
        delete query[item]
      } else {
        if (query[item].includes(',')) {
          query[item] = query[item].split(',')
        }
        query[item] = APP.POIlayers[this.POIlayer].filters[item].parse(
          query[item]
        )
        if (query[item] == null) {
          delete query[item]
        }
      }
    }

    if (Object.keys(query).length === 0) {
      return null
    }

    return query
  }

  getArticle = () => {
    const params = this.getParams()
    if (
      [
        this.posAndZoom,
        this.backgroundLayer,
        this.snowLayer,
        this.POIlayer,
        this.filters,
        this.article
      ].every((x) => x == null)
    ) {
      // POI
      if (params.length === 3) {
        if (
          params[0] in APP.POIlayers &&
                    params[1] in APP.POIlayers[params[0]].list
        ) {
          APP.promise.then(() => {
            APP.POIlayers[params[0]].list[params[1]].select()
            APP.POIlayers[params[0]].list[params[1]].article.open()
            APP.map.flyTo(
              [
                APP.POIlayers[params[0]].list[params[1]]
                  .latitude,
                APP.POIlayers[params[0]].list[params[1]]
                  .longitude
              ],
              13,
              { animate: false }
            )
          })
        }
      } else if (params.length === 2) {
        // Region
        if (params[1] in APP.regions) {
          APP.promise.then(() => {
            APP.regions[params[1]].select()
            APP.regions[params[1]].article.open()
            APP.regions[params[1]].layer.eachLayer((layer) => {
              if (layer instanceof L.Path) {
                APP.map.flyToBounds(layer.getBounds(), {
                  animate: false
                })
              }
            })
          })
        }
      }
    }

    return null
  }

  get = () => {
    return {
      center: this.getPosAndZoom()?.center,
      zoom: this.getPosAndZoom()?.zoom,
      backgroundLayer: this.getBackgroundLayer(),
      snowLayer: this.getSnowLayer(),
      POIlayer: this.getPOIlayer(),
      filters: this.getFilters(),
      article: this.getArticle()
    }
  }

  update = () => {
    // Background layer
    let uri_ = `/${APP.state.backgroundLayer}`

    // Snow layer
    if (APP.state.snowLayer) {
      uri_ += `/${APP.state.snowLayer}`
    }

    // POI layer
    if (APP.state.POIlayer) {
      uri_ += `/${APP.state.POIlayer}`
    }

    // Map center and zoom
    uri_ += `~${Number(APP.state.center.lat.toFixed(4))},${Number(
            APP.state.center.lng.toFixed(4)
        )},${APP.state.zoom}`

    // Article
    if (APP.state.article) {
      const family = APP.state.article.family
      if (family === 'regions') {
        uri_ = `/report/${APP.state.article.name}`
      } else {
        if (family in APP.POIlayers) {
          const poi =
                        APP.POIlayers[family].list[APP.state.article.name]
          uri_ = `/${poi.family}/${poi.name}/${uri(
                        poi.title
                    )}`
        }
      }
      APP.state.refresh()
    }

    // Apply
    history.replaceState({ snowmap: true }, '', uri_)
  }

  onpopstate = () => {
    if (
      APP.device.type === 'mobile' &&
            (APP.ui.tabs.getCurrent() !== 'map' || APP.ui.tooltip.isOpen())
    ) {
      // Close current menu and stay on Snowmap
      history.pushState({ snowmap: true }, '', '')

      return
    }

    // Go back to previous page before Snowmap
    while (history.state != null && 'snowmap' in history.state) {
      history.back()
    }
    history.back()
  }
}
