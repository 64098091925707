import loading from '../../assets/html/loading.html'
import tooltip from '../../assets/html/tooltip.html'
import aim from '../../assets/html/aim.html'
import filtersSlider from '../../assets/html/filters/slider.html'
import filtersCheckbox from '../../assets/html/filters/checkbox.html'
import filtersRange from '../../assets/html/filters/range.html'
import filtersDateRange from '../../assets/html/filters/date-range.html'
import breadcrumbs from '../../assets/html/breadcrumbs.html'
import donateWindow from '../../assets/html/donate-window.html'
import startupMessages from '../../assets/html/startup-messages.html'
import infoPopup from '../../assets/html/info-popup.html'
import dataList from '../../assets/html/data-list.html'
import poiFilters from '../../assets/html/poi-filters.html'
import tabs from '../../assets/html/tabs.html'
import timeline from '../../assets/html/timeline.html'
import mapAttributions from '../../assets/html/map-attributions.html'
import carousel from '../../assets/html/carousel.html'
import scroller from '../../assets/html/scroller.html'
import legend from '../../assets/html/legend.html'
import searchBar from '../../assets/html/search-bar.html'
import articlesRefuges from '../../assets/html/articles/refuges.html'
import articlesStations from '../../assets/html/articles/stations.html'
import articlesTopos from '../../assets/html/articles/topos.html'
import articlesFilters from '../../assets/html/articles/filters.html'
import articlesWebcams from '../../assets/html/articles/webcams.html'
import articlesRegions from '../../assets/html/articles/regions.html'
import articlesTracks from '../../assets/html/articles/tracks.html'
import articlesSorties from '../../assets/html/articles/sorties.html'
import mapInfo from '../../assets/html/map-info.html'
import updateWindow from '../../assets/html/update-window.html'
import mapButtons from '../../assets/html/map-buttons.html'
import tabsBookmarks from '../../assets/html/tabs/bookmarks.html'
import tabsReportsList from '../../assets/html/tabs/reports-list.html'
import tabsMap from '../../assets/html/tabs/map.html'
import tabsBookmarksPagesRefuges from '../../assets/html/tabs/bookmarks_pages/refuges.html'
import tabsBookmarksPagesStations from '../../assets/html/tabs/bookmarks_pages/stations.html'
import tabsBookmarksPagesTopos from '../../assets/html/tabs/bookmarks_pages/topos.html'
import tabsBookmarksPagesWebcams from '../../assets/html/tabs/bookmarks_pages/webcams.html'
import tabsBookmarksPagesRegions from '../../assets/html/tabs/bookmarks_pages/regions.html'
import tabsBookmarksPagesTracks from '../../assets/html/tabs/bookmarks_pages/tracks.html'
import tabsBookmarksPagesSorties from '../../assets/html/tabs/bookmarks_pages/sorties.html'
import tabsSettings from '../../assets/html/tabs/settings.html'

export default {
  'loading.html': loading,
  'tooltip.html': tooltip,
  'aim.html': aim,
  'filters/slider.html': filtersSlider,
  'filters/checkbox.html': filtersCheckbox,
  'filters/range.html': filtersRange,
  'filters/date-range.html': filtersDateRange,
  'breadcrumbs.html': breadcrumbs,
  'donate-window.html': donateWindow,
  'startup-messages.html': startupMessages,
  'info-popup.html': infoPopup,
  'data-list.html': dataList,
  'poi-filters.html': poiFilters,
  'tabs.html': tabs,
  'timeline.html': timeline,
  'map-attributions.html': mapAttributions,
  'carousel.html': carousel,
  'scroller.html': scroller,
  'legend.html': legend,
  'search-bar.html': searchBar,
  'articles/refuges.html': articlesRefuges,
  'articles/stations.html': articlesStations,
  'articles/topos.html': articlesTopos,
  'articles/filters.html': articlesFilters,
  'articles/webcams.html': articlesWebcams,
  'articles/regions.html': articlesRegions,
  'articles/tracks.html': articlesTracks,
  'articles/sorties.html': articlesSorties,
  'map-info.html': mapInfo,
  'update-window.html': updateWindow,
  'map-buttons.html': mapButtons,
  'tabs/bookmarks.html': tabsBookmarks,
  'tabs/reports-list.html': tabsReportsList,
  'tabs/map.html': tabsMap,
  'tabs/bookmarks_pages/refuges.html': tabsBookmarksPagesRefuges,
  'tabs/bookmarks_pages/stations.html': tabsBookmarksPagesStations,
  'tabs/bookmarks_pages/topos.html': tabsBookmarksPagesTopos,
  'tabs/bookmarks_pages/webcams.html': tabsBookmarksPagesWebcams,
  'tabs/bookmarks_pages/regions.html': tabsBookmarksPagesRegions,
  'tabs/bookmarks_pages/tracks.html': tabsBookmarksPagesTracks,
  'tabs/bookmarks_pages/sorties.html': tabsBookmarksPagesSorties,
  'tabs/settings.html': tabsSettings
}
