// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../assets/images/mountain-small.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../assets/images/orientation.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../assets/images/distance.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../assets/images/snow_cover.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"map-info\"> <ul> <li data-type=\"altitude\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <span></span> </li> <li data-type=\"orientation\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> <span></span> </li> <li data-type=\"distance\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> <span></span> </li> <li data-type=\"snow\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> <span></span> </li> </ul> </div>";
// Exports
export default code;