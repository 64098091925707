import $ from 'jquery'
import { APP } from '.'
import Carousel from './ui/Carousel'
import SearchBar from './ui/SearchBar'
import ReportsList from './ui/ReportsList'
import Bookmarks from './ui/Bookmarks'
import InfoPopup from './ui/InfoPopup'
import Settings from './ui/Settings'
import Tabs from './ui/Tabs'
import DOMelement from './ui/DOMelement'
import MapButtons from './ui/MapButtons'
import MapAttributions from './ui/MapAttributions'
import Breadcrumbs from './ui/Breadcrumbs'
import MapInfo from './ui/MapInfo'
import Timeline from './ui/Timeline'
import StartupMessages from './ui/StartupMessages'
import UpdateWindow from './ui/UpdateWindow'
import DonateWindow from './ui/DonateWindow'
import Tooltip from './ui/Tooltip'

export default class UI {
  constructor () {
    this.promises = []
  }

  build = () => {
    this.carousel = new Carousel()
    this.searchBar = new SearchBar()
    this.reportsList = new ReportsList()
    this.bookmarks = new Bookmarks()
    this.infoPopup = new InfoPopup()
    this.settings = new Settings()
    this.tabs = new Tabs([this.reportsList, this.bookmarks, this.settings])
    APP.map = new DOMelement('tabs/map.html')
    APP.map.addToDOM('body')
    this.promises = this.promises.concat(APP.map.promises)
    this.buttons = new MapButtons()
    this.mapAttributions = new MapAttributions()
    this.breadcrumbs = new Breadcrumbs()
    this.tooltip = new Tooltip()
    // this.aim = new Aim()
    this.mapInfo = new MapInfo()
    this.timeline = new Timeline()
    this.startupMessages = new StartupMessages()
    this.updateWindow = new UpdateWindow()
    this.donateWindow = new DonateWindow()

    Promise.all(this.promises).then(() => {
      // Add UI elements to map
      this.buttons.addToDOM($('.mainTab.map'))
      this.mapAttributions.addToDOM($('.mainTab.map'))
      this.breadcrumbs.addToDOM($('.mainTab.map'))
      this.tooltip.addToDOM($('.mainTab.map'))
      // this.aim.addToDOM($('.mainTab.map'))
      this.mapInfo.addToDOM($('.mainTab.map'))

      // Add tabs to hamburger
      this.tabs.appendTabs()
    })
  }

  update = async () => {
    await this.buttons.update()
    this.breadcrumbs.update()

    $('body')
      .attr({
        'data-backgroundlayer': APP.state.backgroundLayer,
        'data-snowlayer': APP.state.snowLayer,
        'data-poilayer': APP.state.POIlayer,
        'data-zoom': APP.state.zoom
      })
      .css({ '--zoom': APP.state.zoom })
  }
}
