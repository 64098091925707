import { APP } from '..'

export default class State {
  constructor ({
    center,
    zoom,
    backgroundLayer,
    snowLayer = null,
    POIlayer = null,
    article = null,
    locationEnabled = false
  } = {}) {
    this.center = center
    this.zoom = zoom
    this.backgroundLayer = backgroundLayer
    this.snowLayer = snowLayer
    this.POIlayer = POIlayer
    this.article = article
    this.locationEnabled = locationEnabled
  }

  update = (args) => {
    Object.entries(args).forEach(([key, value]) => {
      if (
        typeof value !== 'function' &&
                (key === 'POIlayer' || // POI layer can be undefined
                    key === 'snowLayer' || // Snow layer can be undefined
                    value != null)
      ) {
        if (value == null) {
          value = null
        }
        this[key] = value
      }
    })

    // Add to localStorage
    localStorage.setItem('state', JSON.stringify(this))
  }

  refresh = () => {
    this.update(this)
  }

  updateFromUI = async () => {
    this.update(await APP.ui.buttons.getState())
  }

  updateFromURL = () => {
    const url = APP.urlHandler.get()
    const set = [...new Set(Object.values(url))]
    if (set.length === 1 && set[0] == null) {
      return false
    }
    this.update(APP.urlHandler.get())
    return true
  }

  updateFromLocalStorage = () => {
    let data = localStorage.getItem('state')
    if (data) {
      data = JSON.parse(data)
      this.update(data)
      return true
    }
    return false
  }
}
