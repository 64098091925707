import $ from 'jquery'
import DOMelement from './DOMelement'

export default class UpdateWindow extends DOMelement {
  constructor () {
    super('update-window.html')
  }

  setTriggers = () => {
    $(this.document)
      .find('.install')
      .off()
      .on('click', () => {
        window.location.reload(true)
      })
    $(this.document)
      .find('.later')
      .off()
      .on('click', () => {
        $(this.document).remove()
      })
  }
}
