import { APP } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'
import { formatDate } from '@/tools'

export default class Timeline extends DOMelement {
  constructor () {
    super('timeline.html')
  }

  set (indexes) {
    Promise.all(this.promises).then(() => {
      $(this.document)
        .find('input[type="range"]')
        .attr({
          min: 0,
          max: indexes,
          step: 1
        })
        .val(0)
        .trigger('input')
        .trigger('change')
    })
  }

  togglePlay = () => {
    if (this.interval == null) {
      this.interval = setInterval(() => {
        const value = parseInt(
          $(this.document).find('input[type="range"]').val()
        )
        $(this.document)
          .find('input[type="range"]')
          .val(value + 1)
          .trigger('input')
          .trigger('change')
        if (
          value ===
                    parseInt($(this.document).find('input[type="range"]').val())
        ) {
          $(this.document)
            .find('input[type="range"]')
            .val(0)
            .trigger('input')
            .trigger('change')
        }
      }, 1000)

      $(this.document)
        .find('button.play')
        .addClass('playing')
        .html('Stop')
    } else {
      clearInterval(this.interval)
      this.interval = null
      $(this.document)
        .find('button.play')
        .removeClass('playing')
        .html('Lecture')
    }
  }

  setTriggers = () => {
    $(this.document)
      .find('input[type="range"]')
      .on('input', (event) => {
        const thumbWidth = parseInt(
          $(this.document).css('--filters-button-size')
        )
        const max = Number($(event.target).attr('max'))
        const value = Number($(event.target).val())
        const valueDecimal = value / max
        const valuePercent = valueDecimal * 100

        let date = new Date()
        date = new Date(date.setHours(date.getHours() + value))

        $(this.document)
          .find('input[type=range]+label')
          .css({
            left:
                            'calc(' +
                            valuePercent +
                            '% - ' +
                            thumbWidth * valueDecimal +
                            'px)'
          })
          .find('span.time')
          .html(formatDate(date, 'hour'))

        $(this.document)
          .find('span.date')
          .html(formatDate(date, 'shortFrenchDate'))
      })
      .on('change', (event) => {
        // Set layer tiles URL
        const value = Number($(event.target).val())
        APP.snowLayers.fresh_snow.setCurrentTime(value)
        APP.map.pipette()
      })
      .trigger('input')
      .trigger('change')

    $(this.document)
      .find('button.play')
      .on('click', () => {
        this.togglePlay()
      })

    $(this.document)
      .find('button.next, button.previous')
      .on('click', (event) => {
        let n = 1
        const min = Number(
          $(this.document).find('input[type="range"]').attr('min')
        )
        const max = Number(
          $(this.document).find('input[type="range"]').attr('max')
        )
        const value = Number(
          $(this.document).find('input[type="range"]').val()
        )
        if ($(event.target).is('.previous')) {
          n = -1
        }
        $(this.document)
          .find('input[type="range"]')
          .val(Math.min(max, Math.max(min, value + n)))
          .trigger('input')
          .trigger('change')
      })
  }
}
