// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/images/website.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/images/logo-refugesinfo.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/images/cc.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<article class=\"refuge\"> <h1>Refuge</h1> <input type=\"checkbox\" class=\"bookmark\" name=\"bookmark\" id=\"bookmark\"> <label for=\"bookmark\"></label> <section> <h2 class=\"title\"></h2> <h5 class=\"type\"></h5> <ul class=\"photos\"> <li class=\"blank\" data-url=\"\" style=\"background-image:url(../../images/empty.svg)\"></li> <li class=\"moreImages\"><span></span></li> </ul> <h3 class=\"remarks\">Remarques</h3> <div class=\"paragraph\" data-name=\"remarks\"></div> <h3 class=\"access\">Accès</h3> <div class=\"paragraph\" data-name=\"access\"></div> <h3 class=\"owner\">Propriétaire</h3> <div class=\"paragraph\" data-name=\"owner\"></div> <p class=\"links\"> <a href=\"\" class=\"external\" target=\"_blank\" data-site=\"website\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> Site officiel </a> <a href=\"https://www.refuges.info/\" class=\"external\" target=\"_blank\" data-site=\"refuges.info\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> Refuges.info </a> <a href=\"http://creativecommons.org/licenses/by-sa/3.0/deed.fr\" class=\"external\" target=\"_blank\" data-license=\"cc-by-sa\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> BY-SA </a> </p> </section> </article>";
// Exports
export default code;