import $ from 'jquery'
import L from 'leaflet'
import { APP } from '..'
import SnowLayer from './SnowLayer'

export default class TimeLayer extends SnowLayer {
  constructor (data) {
    super(data)
    this.indexes = data.indexes
    this.setCurrentTime(0)
    APP.ui.timeline.set(this.indexes)

    this.layer.on('remove', () => {
      $(APP.ui.timeline.document).remove()
    })
  }

  setCurrentTime = (value) => {
    this.currentTime = value

    this.layer.eachLayer((layer) => {
      layer._url = this.tiles.replace('{t}', value)

      let wasAnimated = false

      // Prevent _tileOnLoad/_tileReady from re-triggering an opacity animation
      if ('_map' in layer) {
        wasAnimated = layer._map._fadeAnimated
        layer._map._fadeAnimated = false
      }

      // Replace tiles sources in DOM
      for (const key in layer._tiles) {
        const tile = layer._tiles[key]
        if (tile.current && tile.active) {
          let src = tile.el.src.split('/')
          const index = src.indexOf(src.at(-4))
          src[index] = src[index].replace(/\d+/, this.currentTime)
          src = src.join('/')
          const img = new Image()
          img.onload = () => {
            L.Util.requestAnimFrame(() => {
              tile.el.style.display = ''
              tile.el.src = src
            })
          }
          img.onerror = () => {
            L.Util.requestAnimFrame(() => {
              tile.el.style.display = 'none'
            })
          }
          img.src = src
        }
      }

      // Re-enable animation
      if (wasAnimated) {
        setTimeout(() => {
          layer._map._fadeAnimated = wasAnimated
        }, 5000)
      }
    })
  }

  addedToMapTrigger_ = this.addedToMapTrigger
  addedToMapTrigger = () => {
    this.addedToMapTrigger_()
    APP.ui.timeline.addToDOM($('.mainTab.map'))
  }
}
