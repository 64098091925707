import $ from 'jquery'
import { APP } from '..'
import L from 'leaflet'
import MapObject from './MapObject'
import Article from '@/ui/Article'
import { download, formatDate, uri } from '@/tools'
import empty from '../../assets/images/empty.svg'
import profile from '@/utils/elevation/profile'
import togpx from 'togpx'

export default class Track extends MapObject {
  static icons = {
    moveHandle: new L.Icon({
      className: 'draw moveHandle',
      iconUrl: 'data:image/svg+xml;base64,' + btoa(empty),
      name: 'trackIcon'
    }),
    addHandle: new L.Icon({
      className: 'draw addHandle',
      iconUrl: 'data:image/svg+xml;base64,' + btoa(empty),
      name: 'trackIcon'
    })
  }

  static fetchLocalStorage = () => {
    if (localStorage.getItem('tracks')) {
      const tracks = JSON.parse(localStorage.getItem('tracks'))

      tracks.forEach((item) => {
        const track = new Track(item)
        track.createClone()
        APP.tracks.push(track)
        APP.ui.bookmarks.updateRoot()
      })
    }
  }

  static updateLocalStorage = () => {
    const tracks = []
    APP.tracks.forEach((track) => {
      tracks.push({
        distance: track.distance,
        latLngs: track.polyline.getLatLngs(),
        max_elevation: track.max_elevation,
        min_elevation: track.min_elevation,
        elevation_down: track.elevation_down,
        elevation_up: track.elevation_up,
        title: track.title,
        name: track.name,
        geojson: track.geojson,
        visible: APP.map.hasLayer(track.featureGroup)
      })
    })

    localStorage.setItem('tracks', JSON.stringify(tracks))

    APP.ui.bookmarks.updateRoot()
  }

  static parseGeoJSON = (geojson) => {
    geojson = new L.GeoJSON(geojson)
    let latLngs = []
    geojson.eachLayer((layer) => {
      if (layer instanceof L.Path) {
        if (layer.getLatLngs().length > latLngs.length) {
          latLngs = layer.getLatLngs()
        }
      }
    })

    return latLngs
  }

  constructor (data) {
    super({
      name: Date.now(),
      family: 'tracks'
    })

    // Set data
    this.visible = true
    this.title = `snowmap_${formatDate(
            new Date(),
            'yyyy-mm-dd_hh-mm'
        )}`
    this.history = []
    this.parent = { attributes: this.getAttributes() }

    // Layers
    this.featureGroup = new L.FeatureGroup()
    this.polyline = new L.Polyline([], {
      className: 'track',
      parent: this
    })
      .on('click', () => {
        APP.map.clicked(this)
      })
      .addTo(this.featureGroup)
      .bringToFront()
    this.moveHandles = new L.LayerGroup().addTo(this.featureGroup)
    this.addHandles = new L.LayerGroup().addTo(this.featureGroup)

    // If data, track is being fetched from localStorage or POI
    if (data) {
      this.import(data)
    } else {
      // If not, track is being drawn
      this.toggleOnMap(true)
      this.start()
    }

    // Article
    this.article = new Article(this)

    // GeoJSON
    if (!('geojson' in this)) {
      this.geojson = this.polyline.toGeoJSON()
    }

    Promise.all(this.promises).then(() => {
      APP.ui.bookmarks.update()
    })

    // Visibility
    if (this.visible) {
      APP.map.addLayer(this.featureGroup)
    }
  }

  import = (data) => {
    for (const name of Object.keys(this.parent.attributes)) {
      this[name] = undefined
      if (name in data) {
        this[name] = data[name]
      }
    }
    if ('geojson' in data) {
      this.geojson = data.geojson
    }
    if ('name' in data) {
      this.name = data.name
    }

    if ('latLngs' in data) {
      for (const item in data) {
        this.item = data[item]
      }
      this.polyline.setLatLngs(data.latLngs)
    } else if ('geojson' in data) {
      this.polyline.setLatLngs(Track.parseGeoJSON(data.geojson))
    }

    this.createClone()
  }

  hide = () => {
    this.toggleOnMap(false)
    this.cancel()
    const openArticle = Article.getOpenArticle()
    if (openArticle && openArticle.parent === this) {
      Article.close()
    }
  }

  toggleOnMap = (bool) => {
    if (bool) {
      this.featureGroup.addTo(APP.map)
    } else {
      this.featureGroup.remove()
    }
    Track.updateLocalStorage()
  }

  getAttributes = () => {
    const attributes = {}
    for (const [name, attribute] of Object.entries(APP.attributes)) {
      if (attribute.applies_to.includes('tracks')) {
        attributes[name] = attribute
      }
    }

    return attributes
  }

  start = () => {
    APP.map.deselectAll()
    this.mode = 'started'
    $('body').attr({ 'data-draw': 'started' })
    this.selected = true

    // Reset and setup click action
    APP.map.off('click').on('click', (event) => {
      this.moveHandles.clearLayers()

      // Add new clickable non-draggable moveHandle
      const marker = new L.Marker(
        [event.latlng.lat, event.latlng.lng],
        {
          title: '',
          alt: '',
          icon: Track.icons.moveHandle,
          type: 'moveHandle'
        }
      )
        .addTo(this.moveHandles)
        .on('click', () => {
          this.stop()
        })
      $(marker._icon).addClass('lastMoveHandle')

      // Add point to polyline
      const latLngs = this.polyline.getLatLngs()
      latLngs.push(
        new L.LatLng(event.latlng.lat, event.latlng.lng)
      )
      this.polyline.setLatLngs(latLngs).redraw()
    })

    APP.ui.infoPopup.peek('drawing_started')
  }

  // Stops track or editing
  stop = () => {
    // Check that track has started
    if (!['edited', 'started'].includes(this.mode)) {
      throw Error('Cannot stop(), track or edit modes are not started.')
    } else {
      // If polyline is empty, delete
      if (this.polyline.getLatLngs().length <= 1) {
        this.delete()
      } else {
        // Remove handles
        this.moveHandles.clearLayers()
        this.addHandles.clearLayers()

        // Remove polyline history, select it and add it to the tracks list
        this.history = []
        this.select()

        // Clone polyline
        this.createClone()

        Track.updateLocalStorage()

        // Reset geoJSON
        this.geojson = this.polyline.toGeoJSON()

        this.calculateElevation()

        this.article.refresh()
      }

      APP.map.resetClickEvent()
    }

    this.polyline.bringToFront()
  }

  calculateElevation = async () => {
    const promise = profile(this.geojson.geometry)
      .then((response) => {
        this.geojson = {
          ...response,
          properties: {
            max_elevation: response.properties.maxElevation,
            min_elevation: response.properties.minElevation,
            elevation_down: response.properties.elevationDown,
            elevation_up: response.properties.elevationUp
          }
        }

        for (const [key, value] of Object.entries(this.geojson.properties)) {
          this[key] = value
        }
        Track.updateLocalStorage()
        APP.ui.bookmarks.update()
      })

    this.promises.push(promise)

    return promise
  }

  cancel = () => {
    if (!['edited', 'started'].includes(this.mode)) {
      // Deselect
      APP.map.deselectAll()
    } else {
      switch (this.mode) {
        case 'edited':
          // Revert polyline back to first history item and erase history
          this.polyline.setLatLngs(this.history[0])
          this.history = []

          // Stop editing and enable click action on polylines
          this.stop()
          this.select(this.layer)
          break
        case 'started':
          this.delete()
          break
      }
    }
  }

  undo = () => {
    const latLngs = this.polyline.getLatLngs()
    const moveHandles = this.moveHandles.getLayers()
    switch (this.mode) {
      case 'edited':
        if (this.history.length > 1) {
          this.history.pop()
          this.polyline.setLatLngs(this.history.at(-1))
          this.updateHandles()
        }
        break
      case 'started':
        latLngs.pop()
        this.polyline.setLatLngs(latLngs).redraw()

        // Remove last moveHandle
        if (moveHandles.length > 0) {
          this.moveHandles.removeLayer(moveHandles.at(-1))
        }

        // Add new clickable non-draggable moveHandle
        if (latLngs.length > 0) {
          const marker = new L.Marker(
            [latLngs.at(-1).lat, latLngs.at(-1).lng],
            {
              title: '',
              alt: '',
              icon: Track.icons.moveHandle,
              type: 'moveHandle'
            }
          )
            .addTo(this.moveHandles)
            .on('click', () => {
              this.stop()
            })
          $(marker._icon).addClass('lastMoveHandle')
        }
        break
    }

    if (!['edited', 'started'].includes(this.mode)) {
      throw Error('Cannot undo(), track or edit modes are not started.')
    }
  }

  delete = () => {
    this.featureGroup.remove()
    delete APP.tracks[APP.tracks.indexOf(this)]

    APP.map.resetClickEvent()

    $('body').removeAttr('data-draw')
    Track.updateLocalStorage()

    const openArticle = Article.getOpenArticle()
    if (openArticle && openArticle.parent === this) {
      Article.close()
    }

    APP.ui.bookmarks.update()
  }

  createClone = () => {
    this.clone = new L.Polyline(this.polyline.getLatLngs(), {
      className: 'pathClone',
      parent: this.polyline
    })
      .on('click', (event) => {
        event.target.options.parent.fire('click')
      })
      .on('mouseover', (event) => {
        $(event.target.options.parent._path).addClass('hover')
      })
      .on('mouseout', (event) => {
        $(event.target.options.parent._path).removeClass('hover')
      })
      .addTo(this.featureGroup)
      .bringToFront()
  }

  select_ = this.select
  select = () => {
    this.select_()
    $(this.polyline._path).addClass('selected')
    this.mode = 'selected'
    $('body').attr({ 'data-draw': 'selected' })
    this.polyline.bringToFront()
  }

  deselect_ = this.deselect
  deselect = () => {
    this.deselect_()

    $(this.polyline._path).removeClass('selected')
    this.mode = null
    $('body').removeAttr('data-draw')
  }

  edit = () => {
    // Create polyline's history and enable edit mode
    this.clone.remove()
    this.history = [[...this.polyline.getLatLngs()]]
    this.mode = 'edited'
    $('body').attr({ 'data-draw': 'edited' })
    this.updateHandles()

    if (APP.device.type === 'mobile') {
      const openArticle = Article.getOpenArticle()
      if (openArticle && openArticle.parent === this) {
        Article.close()
      }
    }
  }

  information = async () => {
    this.article.open(true)
  }

  // Dragging a moveHandle animates polyline and neighbouring addHandles
  dragMoveHandle (event) {
    // Get position
    const moveHandles = Object.values(this.moveHandles._layers)
    const addHandles = Object.values(this.addHandles._layers)
    const index = moveHandles.indexOf(event.target)

    // Edit polyline point at position and redraw
    const latLngs = this.polyline.getLatLngs()
    latLngs[index] = event.target._latlng
    this.polyline.setLatLngs(latLngs).redraw()

    // Move neighbouring addHandles
    if (index > 0) {
      const previousMoveHandleCoord = moveHandles.at(index - 1).getLatLng()
      const middlePoint = new L.LatLng(
        (previousMoveHandleCoord.lat + event.target._latlng.lat) / 2,
        (previousMoveHandleCoord.lng + event.target._latlng.lng) / 2
      )
      addHandles.at(index - 1).setLatLng(middlePoint)
    }
    if (index < moveHandles.length - 1) {
      const nextMoveHandleCoord = moveHandles.at(index + 1).getLatLng()
      const middlePoint = new L.LatLng(
        (nextMoveHandleCoord.lat + event.target._latlng.lat) / 2,
        (nextMoveHandleCoord.lng + event.target._latlng.lng) / 2
      )
      addHandles.at(index).setLatLng(middlePoint)
    }
  }

  // Dragging an addHandle adds a point to the polyline
  dragStartAddHandle (event) {
    // Get target's position in addHandles list
    const index = Object.values(this.addHandles._layers).indexOf(
      event.target
    )

    // Get polyline's coordinates and insert target's latLng
    const latLngs = this.polyline.getLatLngs()
    latLngs.splice(
      index + 1,
      0,
      new L.LatLng(event.target._latlng.lat, event.target._latlng.lng)
    )
    this.polyline.setLatLngs(latLngs).redraw()
  }

  // Dragging an addHandle animates the polyline
  dragAddHandle (event) {
    // Get moveHandles list and target's position in addHandles list
    const index = Object.values(this.addHandles._layers).indexOf(
      event.target
    )

    // Get polyline's coordinates and insert target's latLng
    const latLngs = this.polyline.getLatLngs()
    latLngs[index + 1] = new L.LatLng(
      event.target._latlng.lat,
      event.target._latlng.lng
    )
    this.polyline.setLatLngs(latLngs).redraw()
  }

  // Stopping to drag an addHandle turns it into a moveHandle and creates two more addHandles
  dragStopAddHandle = () => {
    this.updateHandles()
  }

  updateHandles = () => {
    const latLngs = this.polyline.getLatLngs()

    // Remove handles
    this.addHandles.clearLayers()
    this.moveHandles.clearLayers()

    // Create moveHandles
    latLngs.forEach((latLng) => {
      this.createMoveHandle(latLng.lat, latLng.lng)
    })

    // Create addHandles
    latLngs.forEach((latLng) => {
      const index = latLngs.indexOf(latLng)
      if (index > 0) {
        this.createAddHandle(
          (latLngs[index].lat + latLngs[index - 1].lat) / 2,
          (latLngs[index].lng + latLngs[index - 1].lng) / 2
        )
      }
    })
  }

  createAddHandle (lat, lng) {
    return new L.Marker([lat, lng], {
      title: '',
      alt: '',
      icon: Track.icons.addHandle,
      draggable: true,
      type: 'addHandle'
    })
      .on('drag', (event) => {
        this.dragAddHandle(event)
      })
      .on('dragstart', (event) => {
        this.dragStartAddHandle(event)
      })
      .on('dragend', (event) => {
        this.dragStopAddHandle(event)
        this.historyAdd()
      })
      .addTo(this.addHandles)
  }

  createMoveHandle (lat, lng) {
    new L.Marker([lat, lng], {
      title: '',
      alt: '',
      icon: Track.icons.moveHandle,
      draggable: true,
      type: 'moveHandle'
    })
      .on('drag', (event) => {
        this.dragMoveHandle(event)
      })
      .on('click', (event) => {
        this.clicked(event)
      })
      .on('dragend', () => {
        this.historyAdd()
      })
      .addTo(this.moveHandles)
  }

  // Clicking a moveHandle deletes it and its neigbouring addHandles
  clicked (event) {
    // Get current track, moveHandles list, index of clicked in list, and polyline latLngs
    const moveHandles = Object.values(this.moveHandles._layers)
    const index = moveHandles.indexOf(event.target)
    const latLngs = this.polyline.getLatLngs()

    // Remove polyline point at index, update polyline, add to history, update handles
    latLngs.splice(index, 1)
    this.polyline.setLatLngs(latLngs)
    this.historyAdd()
    this.updateHandles()
  }

  // Adds an entry to a layer's history
  historyAdd = () => {
    this.history.push([...this.polyline.getLatLngs()])
  }

  download = () => {
    download.textFile({
      text: togpx(
        this.geojson,
        {
          creator: 'snowmap',
          featureTitle: () => this.title,
          featureLink: () => 'https://snowmap.fr'
        }
      ),
      filename: `${uri(this.title)}.gpx`,
      contentType: 'application/gpx+xml'
    })
  }

  rename = (name) => {
    this.title = name
    Track.updateLocalStorage()
  }
}
