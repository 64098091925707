// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/images/graph-loading.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/images/annoter.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/images/download.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../../assets/images/bin.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<article class=\"track\"> <h1>Trace</h1> <section> <input type=\"text\" name=\"\" id=\"\" class=\"title\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"Graphique\" class=\"track_graph\"> <ul class=\"actions\"> <li class=\"edit\" data-action=\"edit\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"Annoter\"> <span>Modifier</span> </li> <li class=\"download\" data-action=\"download\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"Télécharger\"> <span>Télécharger</span> </li> <li class=\"delete\" data-action=\"delete\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"Supprimer\"> <span>Supprimer</span> </li> </ul> </section> </article>";
// Exports
export default code;