import L from 'leaflet'
import { APP } from '..'

export default class BackgroundLayer {
  constructor ({
    title,
    name,
    icon,
    tiles,
    minNativeZoom = 0,
    maxNativeZoom = 15,
    zoomOffset = 0,
    mobileZoomOffset
  } = {}) {
    this.title = title
    this.name = name
    this.icon = icon

    if (mobileZoomOffset != null && APP.device.type === 'mobile') {
      zoomOffset = mobileZoomOffset
    }

    this.layer = this.createLayer({
      name,
      tiles,
      minNativeZoom,
      maxNativeZoom,
      zoomOffset,
      instance: this
    })
  }

  createLayer = ({
    name,
    tiles,
    minNativeZoom,
    maxNativeZoom,
    zoomOffset
  } = {}) => {
    const layer = new L.TileLayer(tiles, {
      name,
      parent: this,
      minNativeZoom,
      maxNativeZoom,
      tileSize: 256 / (zoomOffset + 1),
      zoomOffset,
      className: `backgroundLayer ${name}`
    })
      .on('add', (event) => {
        event.target.bringToBack()
      })
      .on('tileerror', (event) => {
        this.handleTileError(event)
      })

    return layer
  }

  handleTileError = (event) => {
    if (event.tile.src.includes('opentopomap-proxy.maphub.net')) {
      const url = event.tile.src
      const s = ['a', 'b', 'c'][Math.floor(Math.random() * 3)]
      const split = url.split('.').at(-2).split('/')
      const y = split.at(-1)
      const x = split.at(-2)
      const z = split.at(-3)
      event.tile.src = `https://${s}.tile.opentopomap.org/${z}/${x}/${y}.png`
    }
  }
}
