import $ from 'jquery'
import DOMelement from './DOMelement'

export default class MapAttributions extends DOMelement {
  constructor () {
    super('map-attributions.html')
  }

  setTriggers = () => {
    $(this.document)
      .off()
      .on('click', () => {
        $(this.document).toggleClass('open')
      })
  }
}
