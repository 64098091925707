import Article from '@/ui/Article'
import { APP } from '..'

export default class MapObject {
  constructor ({ name, family, detailsFetched = false } = {}) {
    this.name = name
    this.family = family
    this.selected = false
    this.promises = []
    this.detailsFetched = detailsFetched

    // Attributes
    this.attributes = {}
    for (const attribute of Object.values(APP.attributes)) {
      if (attribute.applies_to.includes(this.family)) {
        this.attributes[attribute.name] = attribute
      }
    }
  }

  openArticle = () => {
    if (this.article == null) {
      this.article = new Article(this)
    }
    this.article.open()
  }

  select = () => {
    APP.map.deselectAll()
    this.selected = true
    if (this.article == null) {
      this.article = new Article(this)
    }
  }

  deselect = () => {
    this.selected = false
  }
}
