export default function exportData () {
  const outings = localStorage.getItem('sorties') == null
    ? []
    : JSON.parse(localStorage.getItem('sorties')).map((name) => {
      return {
        type: 'Feature',
        properties: {
          type: 'outings',
          name
        },
        geometry: {
          type: 'Point',
          coordinates: [0, 0]
        }
      }
    })

  const huts = localStorage.getItem('refuges') == null
    ? []
    : JSON.parse(localStorage.getItem('refuges')).map((name) => {
      return {
        type: 'Feature',
        properties: {
          type: 'huts',
          name
        },
        geometry: {
          type: 'Point',
          coordinates: [0, 0]
        }
      }
    })

  const routes = localStorage.getItem('topos') == null
    ? []
    : JSON.parse(localStorage.getItem('topos')).map((name) => {
      return {
        type: 'Feature',
        properties: {
          type: 'routes',
          name
        },
        geometry: {
          type: 'Point',
          coordinates: [0, 0]
        }
      }
    })

  const webcams = localStorage.getItem('webcams') == null
    ? []
    : JSON.parse(localStorage.getItem('webcams')).map((name) => {
      return {
        type: 'Feature',
        properties: {
          type: 'webcams',
          name
        },
        geometry: {
          type: 'Point',
          coordinates: [0, 0]
        }
      }
    })

  const resorts = localStorage.getItem('pistes') == null
    ? []
    : JSON.parse(localStorage.getItem('pistes')).map((name) => {
      return {
        type: 'Feature',
        properties: {
          type: 'resorts',
          name
        },
        geometry: {
          type: 'Point',
          coordinates: [0, 0]
        }
      }
    })

  const tracks = localStorage.getItem('tracks') == null
    ? []
    : JSON.parse(localStorage.getItem('tracks')).map((track) => {
      return track.geojson
    })

  const regions = localStorage.getItem('regions') == null
    ? []
    : JSON.parse(localStorage.getItem('regions')).map((name) => {
      return {
        type: 'Feature',
        properties: {
          type: 'regions',
          name
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[[0, 0]]]
        }
      }
    })

  const data = {
    type: 'FeatureCollection',
    features: [
      ...routes,
      ...outings,
      ...webcams,
      ...huts,
      ...resorts,
      ...tracks,
      ...regions
    ]
  }

  const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = 'snowmap.geojson'
  link.click()
  URL.revokeObjectURL(url)
}
