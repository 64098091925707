import $ from 'jquery'
import htmlFiles from './htmlFiles'
import svgFiles from './svgFiles'

export default class DOMelement {
  static library = {}

  constructor (fileURL) {
    this.promises = []
    this.url = fileURL

    // this.type = DOMelement.findType(fileURL)
    this.type = 'html'
    this.promises = [
      DOMelement.getFile(fileURL).then((response) => {
        this.document = DOMelement.parse({
          text: response,
          type: this.type
        })
      })
    ]
  }

  static findType = (url) => {
    const extension = url.split('.').at(-1)
    switch (extension) {
      case 'html':
        return 'html'
      case 'xml':
      case 'kml':
      case 'svg':
        return 'xml'
    }
  }

  static getFile = async (url) => {
    if (Object.keys(htmlFiles).includes(url)) {
      return htmlFiles[url]
    }
    if (Object.keys(svgFiles).includes(url)) {
      return svgFiles[url]
    }
    throw new Error(`Unknown file '${url}'`)
  }

  static parse = ({ text, type } = {}) => {
    switch (type) {
      case 'xml':
        return $.parseXML(text)
      case 'html':
        return $.parseHTML(text)
    }
  }

  addToDOM = async (parent) => {
    const promise = Promise.all(this.promises).then(() => {
      $(parent).append(this.document)

      if ('setTriggers' in this) {
        this.setTriggers()
      }
    })

    this.promises.push(promise)

    return promise
  }

  getCopy = async () => {
    return Promise.all(this.promises).then(() => {
      let string = ''
      if (this.document.length) {
        this.document.forEach((item) => {
          string += new XMLSerializer().serializeToString(item)
        })
      } else {
        string = new XMLSerializer().serializeToString(this.document)
      }

      return string
    })
  }
}
