import L from 'leaflet'
import $ from 'jquery'
import { APP, HOST } from '..'
import Diagram from '@/ui/Diagram'
import MapObject from './MapObject'
import { download, html2pdf, uri } from '@/tools'
import empty from '../../assets/images/empty.svg'

export default class POI extends MapObject {
  constructor () {
    super({
      name: arguments[0].name,
      family: arguments[0].family
    })

    for (const [key, value] of Object.entries(arguments[0])) {
      this[key] = value
    }

    POI.parseAttributes(this)
    this.marker = POI.createMarker(this)
  }

  static createMarker (instance) {
    return new L.Marker([instance.latitude, instance.longitude], {
      parent: instance,
      title: '',
      alt: '',
      icon: new L.Icon({
        className: `icon poi ${instance.family}`,
        iconUrl: 'data:image/svg+xml;base64,' + btoa(empty)
      })
    })
      .on('click', () => {
        APP.map.clicked(instance)
      })
      .on('add', (event) => {
        if (event.target.options.parent.selected) {
          $(event.target._icon).addClass('selected')
        }
      })
  }

  static parseAttributes (instance) {
    for (const key of Object.keys(instance)) {
      if (Object.keys(instance.parent.attributes).includes(key)) {
        const attribute = instance.parent.attributes[key]
        instance[key] = attribute.parse(instance[key])
      }
    }
  }

  getDetails = async () => {
    if (!this.detailsFetched) {
      const url = this.api
      const promise = fetch(url, { cache: 'reload' }).then(async (response) => {
        await response.json().then((response) => {
          const json = response
          for (const item in json) {
            if (item in this.parent.attributes) {
              this[item] = this.parent.attributes[item].parse(
                json[item]
              )
            } else {
              this[item] = json[item]
            }
          }

          if (this.family === 'webcams') {
            this.photos = this.timestamps.map((timestamp) => `${HOST}/api/v2/webcamsImages/${this.name}/${timestamp}`)
          }

          this.detailsFetched = true
        })
      })
      this.promises.push(promise)
      return promise
    }
  }

  createTrack = () => {
    Promise.all(this.promises).then(() => {
      if (
        ![undefined, null, []].includes(this.geojson) &&
                !('track' in this)
      ) {
        try {
          this.track = new L.GeoJSON(this.geojson, {
            className: 'topos'
          })
        } catch (e) {
          throw new Error(`${this.family}, ${this.name}, ${e}`)
        }
      }
    })
  }

  createViewAngle = () => {
    if (this.family === 'webcams' && !('viewAngle' in this)) {
      this.viewAngle = new L.Marker([this.latitude, this.longitude], {
        parent: this,
        title: '',
        alt: '',
        icon: new L.Icon({
          className: 'icon viewAngle',
          iconUrl: 'data:image/svg+xml;base64,' + btoa(empty)
        })
      })

      Promise.all(this.promises).then(() => {
        const diagram = new Diagram(this)

        this.promises = this.promises.concat(diagram.promises)

        // Update viewAngle once promise is resolved
        Promise.all(diagram.promises).then(() => {
          this.viewAngle.setIcon(
            new L.Icon({
              className: 'icon viewAngle',
              iconUrl: diagram.data
            })
          )
        }, diagram)
      })
    }
  }

  select_ = this.select
  select = () => {
    // Get extra data
    if (!this.detailsFetched) {
      this.getDetails()
      this.createViewAngle()
      this.createTrack()
    }
    this.select_()

    APP.ui.tooltip.open(this)

    // Remove marker from markerCluster so that it stays on the map
    if (this.parent.layer.hasLayer(this.marker)) {
      this.parent.layer.removeLayer(this.marker)
      APP.map.addLayer(this.marker)
    }

    Promise.all(this.promises).then(() => {
      if ('icon' in this.marker.options) {
        $(this.marker._icon).addClass('selected')
        if ('viewAngle' in this) {
          APP.map.addLayer(this.viewAngle)
        }
        if ('track' in this) {
          APP.map.addLayer(this.track)
        }
      }
    })

    return this.promises
  }

  deselect_ = this.deselect
  deselect = () => {
    if (this.selected) {
      this.deselect_()

      // Put old selected marker back into markerCluster
      APP.map.removeLayer(this.marker)
      if (APP.state.POIlayer === this.parent.name) {
        APP.map.switchPOIlayer()
        APP.map.switchPOIlayer(this.parent.name)
      }

      if ('_icon' in this.marker) {
        $(this.marker._icon).removeClass('selected')
      }
      if ('track' in this) {
        APP.map.removeLayer(this.track)
      }
      if ('viewAngle' in this) {
        APP.map.removeLayer(this.viewAngle)
      }

      APP.ui.tooltip.close()
    }
  }

  download_pdf = (inputs) => {
    let mode = 'print'
    if ($(inputs[0]).prop('checked')) {
      mode = 'mobile'
    }

    this.article.getCopy().then((response) => {
      const document = $.parseHTML(response)
      $(document)
        .find('h1, ul.actions, ul.photos, p.links, img')
        .remove()
      $(document)
        .find('section > *')
        .each((_, item) => {
          $(document).append(item)
        })
      $(document).find('section').remove()
      $(document)
        .find('ul.data li span.value')
        .each((_, item) => {
          $(item)
            .next()
            .append(': ' + $(item).html())
          $(item).remove()
        })
      html2pdf({
        html: document,
        name: uri(this.title),
        mode
      })
    })
  }

  download_geojson = () => {
    if (this.has_geojson) {
      download.textFile({
        text: L.ConvertCoords.GPX.convert(
          L.geoJSON(this.geojson),
          5
        ).replace('togpx', 'Snowmap'),
        filename: `${uri(this.title)}.gpx`,
        contentType: 'application/gpx+xml'
      })
    }
  }
}
