import $ from 'jquery'
import DOMelement from './DOMelement'

export default class MapInfo extends DOMelement {
  constructor () {
    super('map-info.html')
  }

  updateItem = (name, text, data) => {
    if (typeof text !== 'string' || text.includes('NaN')) {
      $(this.document)
        .find(`ul li[data-type="${name}"]`)
        .removeClass('visible')
      return
    }

    $(this.document)
      .find(`ul li[data-type="${name}"]`)
      .addClass('visible')
      .find('span')
      .html(text)

    if (data && 'color' in data) {
      let a = 1
      if (data.color.r + data.color.g + data.color.b === 0) {
        data.color = {
          r: 0,
          g: 0,
          b: 0
        }
        a = 0
      }
      $(this.document)
        .find(`ul li[data-type="${name}"]`)
        .css({
          '--color': `rgba(${data.color.r}, ${data.color.g}, ${data.color.b}, ${a})`
        })
    }
  }
}
