import { HOST } from '..'

export default async function payload () {
  const list = [
    'colors',
    'attributes',
    'filters',
    'regions',
    'reports',
    'sorties',
    'topos',
    'refuges',
    'stations',
    'webcams',
    'settings',
    'massifs',
    'translations',
    'fresh_snow'
  ]
  const promises = []

  list.forEach((item) => {
    promises.push(
      fetch(`${HOST}/api/${item}`)
        .catch((e) => {
          console.error(`Failed to fetch ${HOST}/api/${item}.`, e)
        })
        .then(async (response) => {
          return await response.json()
        })
    )
  })

  return Promise.all(promises).then((response) => {
    const result = {}

    for (const [index, element] of response.entries()) {
      result[list[index]] = element
    }

    return result
  })
}
