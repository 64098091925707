import distance from '@turf/distance'
import { point as turfPoint } from '@turf/helpers'
import { convertCRS, retry } from '@/tools'
import { HOST } from '..'

export default class ElevationEngine {
  profile = async (coordinates) => {
    const response = await fetch(
      `${HOST}/api/v2/line`,
      {
        method: 'POST',
        body: {
          type: 'LineString',
          coordinates
        }
      }
    )

    return await response.json()
  }

  point = async (coordinates) => {
    return this.ign_point(coordinates).then(async (data) => {
      // Retry failed points with SwissTopo
      let promises = []
      if (data.coordinates[2] == null) {
        promises.push(
          this.swisstopo_point([
            data.coordinates[0],
            data.coordinates[1]
          ]).then((res) => {
            data.coordinates = res.coordinates
          })
        )
      }

      return Promise.all(promises).then(async () => {
        // Retry failed points with ARCGIS
        promises = []
        if (data.coordinates[2] == null) {
          promises.push(
            this.arcgis_point([
              data.coordinates[0],
              data.coordinates[1]
            ]).then((res) => {
              data.coordinates = res.coordinates
            })
          )
        }

        return Promise.all(promises).then(() => {
          return data
        })
      })
    })
  }

  ign_profile = async (coordinates) => {
    const sampling = 100
    const url = [
      'https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevationLine.json?',
            `sampling=${sampling}`,
            `&lon=${coordinates.map((x) => x[1]).join('|')}`,
            `&lat=${coordinates.map((x) => x[0]).join('|')}`,
            '&indent=true'
    ].join('')

    return retry({
      url,
      maxRetries: 5
    })
      .then(async (response) => {
        const data = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: []
          }
        }

        for (const item of Object.values(response.elevations)) {
          if (item.z === -99999) {
            item.z = null
          }
          data.geometry.coordinates.push([
            item.lon,
            item.lat,
            item.z
          ])
        }

        return data
      })
  }

  swisstopo_profile = async (coordinates) => {
    const sampling = 100
    // Convert coordinates to 2056
    for (const n in coordinates) {
      coordinates[n] = convertCRS(
        '4326',
        '2056',
        coordinates[n]
      )
    }

    const geom = {
      type: 'LineString',
      coordinates
    }

    const url = [
      'https://api3.geo.admin.ch/rest/services/profile.json?',
            `geom=${encodeURI(JSON.stringify(geom))}`,
            '&offset=0',
            '&smart_filling=false',
            '&distinct_points=true',
            '&sr=2056',
            `&nb_points=${sampling}`
    ].join('')

    if (url.length > 4094) {
      throw Error(`Request URL is too long (${4421})`)
    }

    return retry({
      url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      maxRetries: 5
    })
      .then((response) => {
        const data = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: []
          }
        }

        // Convert coordinates back to EPSG:4326
        for (const item of Object.values(response)) {
          const latLng = convertCRS('2056', '4326', [
            item.easting,
            item.northing
          ])

          data.geometry.coordinates.push([
            latLng[1],
            latLng[0],
            item.alts.COMB
          ])
        }

        return this.calcualteProperties(data)
      })
  }

  ign_point = async (coordinates) => {
    const url = [
      'https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevation.json?',
            `lon=${coordinates[0]}`,
            `&lat=${coordinates[1]}`,
            '&zonly=true'
    ].join('')

    let tries = 0
    while (true) {
      let response = await fetch(url)
      try {
        response = await response.json().then((response) => {
          return response
        })
      } catch (error) {
        tries += 1
        if (tries >= 5) {
          break
        }
        continue
      }

      if (response.elevations[0] !== -99999) {
        return {
          type: 'Point',
          coordinates: [
            coordinates[0],
            coordinates[1],
            response.elevations[0]
          ]
        }
      }

      tries += 1
      if (tries >= 5) {
        break
      }
    }

    return {
      type: 'Point',
      coordinates: [coordinates[0], coordinates[1], null]
    }
  }

  swisstopo_point = async (coordinates) => {
    // Convert coordinates to 2056
    const coordinates2056 = convertCRS(
      '4326',
      '2056',
      coordinates
    )

    const url = [
      'https://api3.geo.admin.ch/rest/services/height?',
            `easting=${coordinates2056[0]}`,
            `&northing=${coordinates2056[1]}`,
            '&sr=2056'
    ].join('')

    return retry({
      url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      maxRetries: 5
    })
      .then((response) => {
        coordinates[2] = Number(response.height)

        return {
          type: 'Point',
          coordinates: [
            coordinates[0],
            coordinates[1],
            coordinates[2]
          ]
        }
      })
      .catch(() => {
        return {
          type: 'Point',
          coordinates: [coordinates[1], coordinates[0], null]
        }
      })
  }

  arcgis_point = async (coordinates) => {
    let encodedString = {
      x: coordinates[1],
      y: coordinates[0],
      spatialReference: {
        wkid: 4326
      }
    }
    encodedString = encodeURI(JSON.stringify(encodedString))
    const url = [
      'https://signa.ign.es/DotNet/proxy.ashx?',
      'https://elevation.arcgis.com/arcgis/rest/services/WorldElevation/Terrain/ImageServer/identify?f=json',
            `&geometry=${encodedString}`,
            '&returnGeometry=false',
            '&returnCatalogItems=true',
            '&geometryType=esriGeometryPoint',
            '&returnPixelValues=true'
    ].join('')

    return retry({
      url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      maxRetries: 5
    })
      .then(async (response) => {
        coordinates[2] = Number(response.value)

        return {
          type: 'Point',
          coordinates: [
            coordinates[1],
            coordinates[0],
            coordinates[2]
          ]
        }
      })
      .catch(() => {
        return {
          type: 'Point',
          coordinates: [coordinates[0], coordinates[1], null]
        }
      })
  }

  calcualteProperties = (feature) => {
    feature.properties = {}
    let maxElevation = -Infinity
    let minElevation = Infinity
    let elevationUp = 0
    let elevationDown = 0
    let dist = 0

    for (const [n, pnt] of Object.entries(feature.geometry.coordinates)) {
      const a = pnt
      const b = feature.geometry.coordinates[n - 1]

      if (n > 0) {
        // Elevation
        if (![a[2], b[2]].includes(this.noData)) {
          elevationUp += Math.max(0, a[2] - b[2])
          elevationDown += Math.min(0, a[2] - b[2])
          maxElevation = Math.max(maxElevation, pnt[2])
          minElevation = Math.min(minElevation, pnt[2])
        }

        // dist
        dist += distance(
          turfPoint([a[0], a[1]]),
          turfPoint([b[0], b[1]]),
          { units: 'kilometers' }
        )
        feature.geometry.coordinates[n][3] = dist
      } else {
        feature.geometry.coordinates[n][3] = 0
      }
    }

    feature.properties.dist = Number(dist.toFixed(2))
    feature.properties.elevationUp = parseInt(elevationUp)
    feature.properties.elevationDown = parseInt(elevationDown)

    if (isFinite(minElevation)) {
      feature.properties.minElevation = parseInt(minElevation)
    }
    if (isFinite(maxElevation)) {
      feature.properties.maxElevation = parseInt(maxElevation)
    }

    return feature
  }
}
